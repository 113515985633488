h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #475872;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	color: #1b2f63;
	font-size: 1.5rem;
	line-height: 1.975rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #1b2f63;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
}

h5 {
	margin-top: 1.375rem;
	margin-bottom: 0.75rem;
	color: #1b2f63;
	font-size: 0.9375rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
}

h6 {
	margin-top: 1.5rem;
	margin-bottom: 0.875rem;
	color: #1b2f63;
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.footer-left-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 42vw;
	padding-left: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.signoff-left-wrap {
	display: inline-block;
	width: 29%;
	margin-left: 0rem;
	color: #bdbdbd;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.footer-right-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45vw;
	padding-right: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	opacity: 1;
	-webkit-transition: border-color 350ms ease, color 200ms ease;
	transition: border-color 350ms ease, color 200ms ease;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 0.4px;
	text-decoration: none;
}

.footer-signoff-link:hover {
	border-bottom: 1px solid #fff;
	color: #fff;
}

.footer-signoff-link.w--current {
	color: #9fb833;
}

.footer-nav-item {
	margin-top: 0rem;
	padding-top: 0rem;
	padding-right: 1.15rem;
	color: #fff;
	font-size: 0.7rem;
	line-height: 0.9rem;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.footer-nav-item.last {
	padding-right: 0rem;
}

.cb-static {
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static.footer-cb-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-right: 0rem;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.cb-static.footer-cb-left > nav {
	width: 100%;
}

.grip-logo-text {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text.webdevby-white {
	color: #002569;
}

.grip-logo-text.webdevby-white {
	color: #fff;
	font-size: 0.48rem;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.home-content-wrapper {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 3rem;
}

.home-content-wrapper.footer-wrapper {
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0rem;
	background-color: #fff;
}

.container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-top: 0rem;
	padding: 1rem 5%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #092c56;
}

.container.footer-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding: 1rem 5%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px none rgba(255, 255, 255, 0.05);
	background-color: #163b68;
}

.copyright-paragraph {
	display: inline-block;
	margin-bottom: 0px;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.75rem;
	font-weight: 400;
}

.copyright-paragraph h1 {
	display: inline;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	margin: 0px;
	font-size: inherit;
}

.twitter-social-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	margin-bottom: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.instagram-social-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	margin-bottom: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	font-size: 0.9rem;
	line-height: 1.65rem;
	justify-content: center;
}

.facebook-social-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	margin-bottom: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-signoff-link-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-list-item-link {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	-webkit-transition: border-color 350ms ease, color 300ms ease;
	transition: border-color 350ms ease, color 300ms ease;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1.15rem;
	font-weight: 400;
	letter-spacing: 0.4px;
	text-decoration: none;
	text-transform: capitalize;
}

.footer-list-item-link:hover {
	border-bottom: 1px solid #fff;
	color: #fff;
}

.footer-list-item-link.w--current {
	border-bottom: 1px solid #888;
	color: #f9b918;
}

.link-block {
	width: 175px;
}

.navbar {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	background-color: #fff;
}

.hero-slider-section {
	height: auto;
	background-color: transparent;
}

.search-bar {
	position: relative;
	left: 0%;
	top: 0px;
	right: 0%;
	bottom: auto;
	z-index: 0;
	display: none;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.search-bar-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.search-input {
	width: 95%;
	margin-bottom: 0px;
	padding: 2rem 1.25rem;
	border: 1px none #000;
	font-size: 0.85rem;
	font-weight: 300;
}

.search-input-exit {
	display: block;
	width: 5%;
	background-color: transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/exit-icon.svg');
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
}

.search-input-exit.two {
	display: none;
}

.slider {
	width: auto;
	height: 38vh;
}

.slide {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide._2 {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 36, 36, 0.1)), to(rgba(36, 36, 36, 0.1))), url('/20170713122722/local/public/shared/assets/images/websites/KS-sunflowers-Credit-Kansas-Tourism-V2.jpg');
	background-image: linear-gradient(180deg, rgba(36, 36, 36, 0.1), rgba(36, 36, 36, 0.1)), url('/20170713122722/local/public/shared/assets/images/websites/KS-sunflowers-Credit-Kansas-Tourism-V2.jpg');
	background-position: 0px 0px, 50% 50%;
}

.slide._3 {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 36, 36, 0.1)), to(rgba(36, 36, 36, 0.1))), url('/20170713122722/local/public/shared/assets/images/websites/kansas_flag_2.jpg');
	background-image: linear-gradient(180deg, rgba(36, 36, 36, 0.1), rgba(36, 36, 36, 0.1)), url('/20170713122722/local/public/shared/assets/images/websites/kansas_flag_2.jpg');
	background-position: 0px 0px, 50% 95%;
}

.slide._4 {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 36, 36, 0.1)), to(rgba(36, 36, 36, 0.1))), url('/20170713122722/local/public/shared/assets/images/websites/KS-Capitol5-Standard-License-V2.jpg');
	background-image: linear-gradient(180deg, rgba(36, 36, 36, 0.1), rgba(36, 36, 36, 0.1)), url('/20170713122722/local/public/shared/assets/images/websites/KS-Capitol5-Standard-License-V2.jpg');
	background-position: 0px 0px, 50% 60%;
}

.slide._5 {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 36, 36, 0.1)), to(rgba(36, 36, 36, 0.1))), url('/20170713122722/local/public/shared/assets/images/websites/KS-Wheat-Credit-Kansas-Tourism-V2.jpg');
	background-image: linear-gradient(180deg, rgba(36, 36, 36, 0.1), rgba(36, 36, 36, 0.1)), url('/20170713122722/local/public/shared/assets/images/websites/KS-Wheat-Credit-Kansas-Tourism-V2.jpg');
	background-position: 0px 0px, 50% 50%;
}

.slide._6 {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/KS-Capitol-Dome-Interior-2-Credit-Kansas-Tourism-V2.jpg');
	background-position: 50% 75%;
}

.slide-nav {
	display: block;
	height: 2rem;
	padding-top: 0px;
	font-size: 0.6rem;
}

.alert-header-content-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.75rem 0em;
	font-size: 0.875em;
}

.alert-box-close-button {
	position: absolute;
	top: auto;
	right: 3rem;
	z-index: 500;
	width: 25px;
	height: 25px;
	margin-top: 0em;
	margin-right: 0%;
	margin-left: 0em;
	padding-right: 15px;
	padding-left: 0px;
	float: right;
	border-bottom-style: none;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/exit-icon-white.svg');
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: hsla(0, 0%, 100%, 0.8);
	font-size: 0.75rem;
	line-height: 1.5em;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.alert-box-close-button:hover {
	color: #fff;
}

.alert-header-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	padding-right: 3rem;
	padding-left: 3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.alert-header-text {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 1;
	-ms-flex-negative: 1;
	flex-shrink: 1;
}

.aleart-header {
	position: relative;
	display: block;
	padding-top: 0px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
}

.alert-header-inner-text {
	margin-top: 0em;
	margin-right: 1rem;
	margin-bottom: 0em;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 400;
	letter-spacing: 0.5px;
}

.alert-header-section-wrapper {
	display: block;
	overflow: hidden;
	background-color: #092c56;
}

.social-media-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 35px;
	height: 35px;
	margin-right: 0.5rem;
	margin-left: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 1.25px;
	border-color: #f1ad02;
	border-radius: 100px;
	background-color: #1e6295;
	-webkit-transition: border-color 200ms ease, background-color 250ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	transition: border-color 200ms ease, background-color 250ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
}

.social-media-link-block:hover {
	border-style: none;
	border-color: #f1ad02;
	border-radius: 100px;
	background-color: #fcb31c;
	opacity: 1;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.social-media-link-block.twitter {
	margin-right: 0rem;
	padding-top: 2px;
	border-style: none;
	-webkit-transition: background-color 250ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	transition: background-color 250ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
}

.social-media-link-block.twitter:hover {
	background-color: #fcb31c;
	opacity: 1;
}

.news-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 3rem;
	margin-bottom: 1rem;
	padding: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 37, 105, 0.07);
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: box-shadow 250ms ease;
	transition: box-shadow 250ms ease;
	text-decoration: none;
}

.news-cb:hover {
	box-shadow: none;
}

.news-cb.last {
	margin-right: 0rem;
	margin-bottom: 3rem;
	padding-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.paragraph {
	max-width: none;
	margin-bottom: auto;
	padding-bottom: 0rem;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #242424;
	font-size: 1rem;
	line-height: 1.15rem;
	font-weight: 400;
	width: 100%;
	text-align: left;
}

.paragraph.centered {
	max-width: none;
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-size: 0.9rem;
	line-height: 1.15rem;
	font-weight: 400;
	text-align: left;
}

.paragraph.centered.about {
	max-width: 800px;
	font-size: 1.15rem;
	line-height: 1.5rem;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.paragraph.centered.alert {
	max-width: 800px;
	font-size: 1.15rem;
	line-height: 1.5rem;
	text-align: center;
}

.paragraph.link {
	margin-top: 0.5rem;
	margin-bottom: 0rem;
	border-bottom-style: none;
	font-size: 0.9rem;
	letter-spacing: 0px;
}

.paragraph.link:hover {
	border-bottom-style: none;
	color: #002569;
	text-decoration: underline;
}

.paragraph.link.last {
	margin-top: 0rem;
}

.paragraph.event {
	display: none;
	margin-bottom: 1rem;
}

.news-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-decoration: none;
}

.events-inner-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0.5rem;
	padding-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px none rgba(51, 51, 51, 0.1);
	background-color: transparent;
	text-decoration: none;
}

.news-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	max-width: none;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.1);
	background-color: transparent;
}

.news-cb-inner-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	border-bottom: 1px none rgba(36, 36, 36, 0.1);
	background-color: transparent;
}

.news-cb-inner-wrapper.first {
	height: auto;
	padding: 1rem 1.5rem 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-radius: 3px;
	background-color: #fff;
	width: 100%;
	border-bottom: 0px;
}

.news-title {
	display: inline-block;
	width: 100%;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none rgba(0, 37, 105, 0.1);
	-webkit-transition: all 350ms ease, border-color 350ms ease;
	transition: all 350ms ease, border-color 350ms ease;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	font-size: 1.5rem;
	line-height: 1.7rem;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.25px;
}

.news-title:hover {
	border-bottom-color: #002569;
	color: #002569;
	text-decoration: underline;
}

.news-inner-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	width: 100%;
	height: 100%;
	padding: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	background-color: transparent;
}

.h2-heading {
	position: relative;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border-bottom: 4px none #fdb827;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	font-size: 2.25rem;
	line-height: 2rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
	text-transform: none;
}

.h2-heading.alert {
	margin-bottom: 1rem;
}

.home-section {
	height: auto;
	background-color: #fff;
}

.top-header-nav-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.65rem 3rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #474747;
}

.navbar-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.search-section {
	display: block;
	width: auto;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.button {
	padding: 0rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #fafafa #fafafa hsla(0, 0%, 98%, 0.5);
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: border-color 350ms ease;
	transition: border-color 350ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1rem;
	letter-spacing: 0.5px;
}

.button:hover {
	border-bottom-color: #fafafa;
}

.icon-4 {
	display: none;
	padding-left: 5px;
	color: #002569;
	font-size: 0.7rem;
}

.cb-feature {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	padding: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.cb-feature.twitter {
	display: block;
	width: 33.33%;
}

.cb-feature.events {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.3%;
	margin-right: 2rem;
	margin-left: 2rem;
	padding-right: 0rem;
	padding-left: 0rem;
}

.footer-middle-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 10vw;
	padding-right: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.signoff-middle-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 64%;
	margin-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #bdbdbd;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.vertical-footer-divider {
	width: 1px;
	height: 100%;
	background-color: #fff;
}

.slider-content {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-top: 0px;
	padding-bottom: 0%;
	padding-left: 0%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading-3 {
	max-width: 800px;
	margin-top: 0px;
	margin-bottom: 0%;
	padding: 0rem 1rem;
	border-radius: 3px;
	background-color: transparent;
	color: #fff;
	font-size: 2.4rem;
	line-height: 2.5rem;
	font-weight: 700;
	text-align: center;
	text-shadow: 1px 1px 6px #474747;
}

.top-header-nav-link {
	margin-right: 1rem;
	margin-left: 1rem;
	padding: 0rem;
	border-bottom: 1px solid #474747;
	-webkit-transition: border-color 350ms ease, color 350ms ease;
	transition: border-color 350ms ease, color 350ms ease;
	color: #fff;
	font-size: 0.825rem;
	line-height: 0.85rem;
	font-weight: 400;
	letter-spacing: 0.5px;
	text-transform: none;
}

.top-header-nav-link:hover {
	border-bottom: 1px solid #fff;
	color: #fff;
}

.top-header-nav-link.last {
	margin-right: 0rem;
	padding-right: 0rem;
}

.top-header-nav-link.first {
	font-size: 0.825rem;
}

.top-header-nav-link.translate {
	margin-right: 0rem;
	padding-right: 0rem;
	border-bottom-style: none;
}

.top-header-nav-link.translate:hover {
	border-bottom-style: none;
}

.dropdown {
	margin-right: 0px;
	margin-left: 0px;
}

.grip-footer-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.grip-footer-link.white {
	width: 145px;
	margin-top: 0rem;
	margin-bottom: 0px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/globalreach_white.svg');
	background-size: 150px 29px;
}

.content-wrapper {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	padding: 3rem 8%;
	background-color: #f8f8f8;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.4%, 0.98)), to(hsla(0, 0%, 96.4%, 0.98)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 96.4%, 0.98), hsla(0, 0%, 96.4%, 0.98));
	background-position: 0px 0px;
}

.content-wrapper.featured-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 4rem 8%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #f8f8f8;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.4%, 0.98)), to(hsla(0, 0%, 96.4%, 0.98)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 96.4%, 0.98), hsla(0, 0%, 96.4%, 0.98));
	background-position: 0px 0px;
	opacity: 1;
}

.content-wrapper.about-section {
	padding-top: 4rem;
	padding-bottom: 4rem;
	background-color: #fff;
	background-image: none;
	color: #163b68;
}

.content-wrapper.footer-cb-section {
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #fff;
	background-image: none;
}

.alert-cb-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: stretch;
	-ms-flex-align: center;
	align-items: stretch;
}

.alert-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	border: 3px none transparent;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: box-shadow 250ms ease;
	transition: box-shadow 250ms ease;
	text-decoration: none;
	border-bottom: 0px !important;
}

.alert-cb:hover {
	border-style: none;
	border-width: 2px;
	border-color: rgba(0, 37, 105, 0.15);
	border-radius: 5px;
	background-color: transparent;
	box-shadow: none;
}

.alert-cb._2 {
	height: auto;
	margin-left: 5rem;
	border-top: 0.25px solid rgba(255, 255, 255, 0.05);
	background-color: #fff;
	border-bottom: 0px;
}

.icon-img-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 20%;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 3px 0px 0px 3px;
	background-color: #163b68;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
}

.icon-img-wrap._2 {
	background-color: #1e6295;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
}

.icon-img-wrap._3 {
	background-color: #569acc;
}

.icon-img-wrap.second {
	background-color: hsla(0, 0%, 43.1%, 0.1);
}

.icon-img-wrap.alert-pop-up {
	position: relative;
	width: 100%;
	height: 200px;
	margin-bottom: 0rem;
	border-bottom: 1px none #fcb31c;
	border-radius: 0px;
	background-color: #fff;
	background-image: none;
}

.inner-cb-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	padding: 1rem 1.25rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-radius: 0px 3px 3px 0px;
	background-color: #fff;
}

.inner-cb-link.second {
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #fafafa;
}

.cb-text {
	color: #002569;
	font-size: 0.9rem;
	font-style: normal;
}

.cb-text.date {
	display: block;
	margin-bottom: 0px;
	padding: 0.15rem 0.5rem;
	border-radius: 3px;
	background-color: rgba(0, 37, 105, 0.12);
	font-size: 0.6rem;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.cb-text.date {
	padding: 0rem;
	background-color: transparent;
	font-size: 0.6rem;
	line-height: 0.8rem;
}

.cb-text.news-category {
	display: block;
	margin-bottom: 0px;
	padding: 0.15rem 0.5rem;
	border-radius: 3px;
	background-color: rgba(0, 37, 105, 0.12);
	font-size: 0.6rem;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.cb-text.news-category.light-blue {
	display: none;
	margin-bottom: 0rem;
	padding: 0rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	background-color: transparent;
	color: #305fb6;
	line-height: 0.9rem;
}

.cb-text.news-category.light-blue {
	display: block;
	margin-bottom: 0.5rem;
	padding: 0rem;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	background-color: transparent;
	color: #1e6295;
	font-size: 0.825rem;
	line-height: 0.9rem;
}

.cb-text.date {
	margin-top: 0rem;
	margin-bottom: 0rem;
	color: #1e6295;
	font-size: 0.75rem;
	line-height: 0.8rem;
	font-weight: 700;
	letter-spacing: 0.5px;
}

.cb-text.date._1 {
	margin-top: 0.5rem;
	margin-bottom: 0rem;
	color: #1e6295;
	font-size: 0.75rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
}

.cb-text.announcements {
	display: block;
	margin-bottom: 0px;
	padding: 0.15rem 0.5rem;
	border-radius: 3px;
	background-color: rgba(0, 37, 105, 0.12);
	font-size: 0.6rem;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.cb-text.announcements.yellow {
	display: block;
	margin-bottom: 0.5rem;
	padding: 0rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	background-color: transparent;
	color: #1e6295;
	font-size: 0.825rem;
	line-height: 0.9rem;
	font-weight: 700;
}

.cb-text.time {
	margin-top: 0.25rem;
	margin-bottom: 1rem;
	color: #1e6295;
	font-size: 0.75rem;
	line-height: 0.8rem;
	font-weight: 700;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.heading-4 {
	margin-top: 0px;
	margin-bottom: 0.35rem;
	color: #163b68;
	font-size: 1.5rem;
	line-height: 1.7rem;
	font-weight: 400;
	width: 100%;
	letter-spacing: 0.25px;
}

.alert-img {
	display: block;
	width: 50px;
	height: 100%;
}

.alert-img.alerts-pop-up {
	width: 60px;
}

.icon-5 {
	margin-top: auto;
	margin-bottom: auto;
	padding: 0.25rem;
	font-size: 1.5rem;
}

.icon-6 {
	margin-top: auto;
	margin-bottom: auto;
	font-size: 1.5rem;
}

.cb-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.cb-wrapper._2 {
	margin-top: 3rem;
}

.invisible-block {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	height: 130px;
	background-color: transparent;
}

.dropdown-icon {
	width: 10px;
	height: 10px;
	margin-left: 0.5rem;
	padding-left: 0px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/navigation-blue-arrow-icon.svg');
	background-position: 50% 50%;
	background-size: auto;
	background-repeat: no-repeat;
}

.dropdown-icon.translate {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/navigation-white-arrow-icon.svg');
}

.header-underline {
	display: none;
	width: 40px;
	height: 1.5px;
	margin-top: 1rem;
	margin-right: 10px;
	margin-bottom: 1rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	background-color: #f1ad02;
}

.cb-feature-heading-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.view-all-link {
	margin-bottom: 0.25rem;
	border-bottom: 1px solid rgba(22, 59, 104, 0.4);
	-webkit-transition: border-color 200ms ease;
	transition: border-color 200ms ease;
	color: #163b68;
	font-size: 1rem;
	line-height: 1.15rem;
	font-weight: 400;
	letter-spacing: 0.5px;
	text-decoration: none;
}

.view-all-link:hover {
	border-bottom-color: #163b68;
	opacity: 1;
}

.text-link {
	border-bottom: 1px solid rgba(22, 59, 104, 0.25);
	color: #1e6295;
	line-height: 0.9rem;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-color: #163b68;
}

.calendar-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-bottom: 1rem;
	padding: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 3px 3px 1px;
	border-color: transparent transparent rgba(0, 37, 105, 0.07);
	border-radius: 3px;
	background-color: #fff;
	-webkit-transition: box-shadow 350ms ease, -webkit-transform 350ms ease-out;
	transition: box-shadow 350ms ease, -webkit-transform 350ms ease-out;
	transition: transform 350ms ease-out, box-shadow 350ms ease;
	transition: transform 350ms ease-out, box-shadow 350ms ease, -webkit-transform 350ms ease-out;
	text-decoration: none;
}

.calendar-cb:hover {
	border-style: none;
	border-width: 2px 2px 1px;
	border-color: rgba(0, 37, 105, 0.15) rgba(0, 37, 105, 0.15) rgba(0, 37, 105, 0.07);
	border-radius: 3px;
	background-color: #fff;
	box-shadow: none;
	-webkit-transform: translate(0px, -4px);
	-ms-transform: translate(0px, -4px);
	transform: translate(0px, -4px);
}

.calendar-cb.last {
	height: auto;
	margin-bottom: 3rem;
	padding-top: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.calendar-date-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 100%;
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	background-color: #1e6295;
}

.calendar-cb-text {
	margin-bottom: 0.15rem;
	color: #fff;
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 400;
	text-transform: uppercase;
}

.calendar-cb-text._2 {
	color: #fff;
	font-size: 2rem;
	line-height: 2rem;
}

.calendar-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	max-width: none;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.1);
	background-color: transparent;
}

.text-block-3 {
	max-width: 700px;
	margin-bottom: 0rem;
	padding: 0.5rem 1rem 0rem;
	border-radius: 3px;
	background-color: transparent;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-shadow: 1px 1px 6px #474747;
}

.slider-right-arrow {
	top: auto;
	display: block;
	height: 100%;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.calendar-inner-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	padding: 0rem 0.5rem 0rem 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-radius: 0px;
	background-color: transparent;
}

.twitter-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	max-width: none;
	margin-top: 0.5rem;
	margin-bottom: 2rem;
	padding: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.1);
	background-color: transparent;
}

.heading-title-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	margin-left: 0px;
	padding: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.about-us-heading-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.about-cb-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cb-wrapper.footer-notice {
	margin-top: 1rem;
}

.button-wrapper {
	position: static;
	top: 12%;
	z-index: 15;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	padding: 0rem 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 37, 105, 0.1);
	border-radius: 3px;
	background-color: transparent;
}

.primary-button {
	display: inline-block;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 0.85rem 3.25rem 0.85rem 1.5rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none !important;
	border-width: 1px;
	border-color: #fff !important;
	border-radius: 3px;
	background-color: #163b68;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/white-arrow_1.svg');
	background-position: 85% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	-webkit-transition: all 350ms ease, border-color 350ms ease-out;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff !important;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	text-decoration: none !important;
}

.primary-button:hover {
	border-style: none;
	border-color: rgba(255, 255, 255, 0.3);
	background-color: #fcb31c !important;
	background-position: 90% 50%;
}

.primary-button.style {
	margin-right: 1rem;
	padding: 0.85rem 3.25rem 0.85rem 1.5rem;
	background-position: 85% 50%;
}

.primary-button.style:hover {
	background-position: 90% 50%;
}

.twitter-embed-wrapper {
	overflow: hidden;
	width: 100%;
	max-width: none;
	border-radius: 3px;
}

.fb-embed {
	display: block;
	overflow-x: hidden;
	overflow-y: scroll;
	max-height: 275px;
	max-width: 100%;
	min-height: 250px;
	margin-right: auto;
	margin-left: auto;
}

.about-cb-content {
	width: 100%;
	margin-right: 0rem;
	margin-left: 0rem;
}

.div-block-98 {
	width: 21px;
	height: 21px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/yellow-arrow.svg');
	background-position: 50% 50%;
	background-size: auto;
	background-repeat: no-repeat;
}

.div-block-98.blue {
	width: 21px;
	height: 21px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/yellow-arrow.svg');
}

.inner-cb-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: center;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 100%;
}

.arrow-wrapper {
	margin-bottom: auto;
	margin-left: 0.5rem;
	padding-top: 0rem;
}

.section-arrow {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -64px;
	z-index: 100;
	width: 65px;
	height: 65px;
	margin-right: auto;
	margin-left: auto;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/white-arrow.svg');
	background-position: 50% 0%;
	background-size: auto;
	background-repeat: no-repeat;
}

.section-arrow.alert-pop-up {
	top: -4px;
	bottom: auto;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/white-arrow.svg');
}

.cb-inner-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 75%;
	height: 100%;
	padding-top: 10px;
	padding-right: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.contracts-img {
	display: block;
	width: 60px;
}

.header-nav-wrappper {
	padding-right: 0rem;
	padding-left: 0rem;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.nav-link {
	position: relative;
	display: block;
	padding: 0.5rem 1.25rem;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.nav-link.w--current {
	background-color: rgba(3, 52, 102, 0.6);
	color: #f9b604;
}

.nav-link.mobile {
	display: none;
}

.left-slider-arrow {
	top: auto;
	display: block;
	height: 100%;
}

.slider-section-wrapper {
	margin-top: 120px;
}

.link {
	border-bottom: 1px solid rgba(84, 159, 196, 0.35);
}

.link:hover {
	border-bottom-style: solid;
	border-bottom-color: #549fc4;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.inside-content-background {
	position: relative;
	display: block;
	max-width: 1400px;
	margin-top: -5px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 1.5rem 4rem;
	border-top: 5px solid #f1ad02;
	background-color: #fff;
	min-height: 600px;
}

.breadcrumb-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 0em 0.125rem 1rem;
	padding-left: 0px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.45rem;
	float: left;
}

.paragraph-text {
	margin-bottom: 0.75rem;
	color: #242424;
	font-size: 1rem;
	line-height: 1.4em;
}

.paragraph-text.right-sidebar {
	font-size: 0.875rem;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.main-content-column {
	padding-right: 0px;
	padding-left: 0.5rem;
}

.inside-page-header-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.main-content-wrapper {
	display: block;
	width: 95%;
	margin-right: auto;
	margin-left: auto;
}

.left-nav-column {
	padding-right: 1rem;
	padding-left: 0px;
}

.heading-link {
	border-bottom: 1px solid rgba(84, 159, 196, 0.35);
	color: #277f8b;
	font-size: 1rem;
}

.heading-link:hover {
	border-bottom-style: solid;
	border-bottom-color: #549fc4;
}

.heading-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.heading-link.breadcrumb-link {
	border-bottom-color: #828282;
	-webkit-transition: border-color 350ms ease;
	transition: border-color 350ms ease;
	color: #163b68;
	font-size: 0.85rem;
	line-height: 0.6874rem;
	font-weight: 400;
	letter-spacing: 0.05px;
	text-decoration: none;
	text-transform: none;
}

.heading-link.breadcrumb-link:hover {
	border-bottom-color: transparent;
}

.heading-link.h5-link {
	border-bottom-color: rgba(9, 44, 86, 0.15);
	-webkit-transition: color 350ms ease;
	transition: color 350ms ease;
	color: #092c56;
	letter-spacing: 0px;
	text-decoration: none;
}

.heading-link.h5-link:hover {
	border-bottom-color: transparent;
	color: #176a99;
}

.heading-link.h6-link {
	border-bottom-color: rgba(9, 44, 86, 0.15);
	color: #163b68;
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: 400;
	letter-spacing: 0.5px;
	text-decoration: none;
}

.heading-link.h6-link:hover {
	border-bottom-color: transparent;
	color: #176a99;
}

.heading-link.h2-link {
	border-bottom-color: rgba(9, 44, 86, 0.15);
	-webkit-transition: color 350ms ease;
	transition: color 350ms ease;
	color: #092c56;
	font-size: 1.875rem;
	line-height: 2.175rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
}

.heading-link.h2-link:hover {
	border-bottom-color: transparent;
	color: #176a99;
}

.heading-link.h1-link {
	border-bottom-color: rgba(9, 44, 86, 0.15);
	-webkit-transition: color 350ms ease;
	transition: color 350ms ease;
	color: #092c56;
	font-size: 2.25rem;
	line-height: 2.9rem;
	letter-spacing: 0.75px;
	text-decoration: none;
}

.heading-link.h1-link:hover {
	border-bottom-color: transparent;
	color: #1e6295;
}

.heading-link.h4-link {
	border-bottom-color: rgba(9, 44, 86, 0.15);
	color: #092c56;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
}

.heading-link.h4-link:hover {
	border-bottom-color: transparent;
	color: #176a99;
}

.heading-link.h3-link {
	border-bottom-color: rgba(9, 44, 86, 0.15);
	-webkit-transition: color 350ms ease;
	transition: color 350ms ease;
	color: #092c56;
	font-size: 1.5rem;
	line-height: 1.975rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
}

.heading-link.h3-link:hover {
	border-bottom-color: transparent;
	color: #176a99;
}

.breadcrumb-current-page {
	color: #163b68;
	font-size: 0.85rem;
	line-height: 0.6875rem;
	font-weight: 700;
	text-transform: none;
}

.left-nav-list-content-wrapper {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: 0rem;
	margin-right: 0.5em;
	padding-bottom: 1.5em;
	border-right: 1px none rgba(0, 0, 0, 0.08);
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #dd4639;
	color: #fff;
}

.form-field-label {
	color: #163b68;
	font-size: 1rem;
	font-weight: 400;
}

.heading-5 {
	margin-top: 1.5rem;
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0.75px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.08);
	font-size: 0.875rem;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #569937;
	color: #fff;
}

.main-content-wrap.no-sidebars {
	padding-right: 0rem;
}

.inside-page-header-section {
	position: relative;
	height: 15rem;
	background-color: transparent;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.section-wrapper {
	margin-top: 80px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.4%, 0.98)), to(hsla(0, 0%, 96.4%, 0.98)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 96.4%, 0.98), hsla(0, 0%, 96.4%, 0.98));
	background-position: 0px 0px;
}

.heading-6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	color: #163b68;
	font-size: 2.25rem;
	line-height: 3rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	display: inline-block;
}

.heading-7 {
	color: #092c56;
	font-weight: 400;
}

.heading-8 {
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0.4px;
}

.heading-9 {
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0.2px;
}

.heading-10 {
	color: #092c56;
	font-weight: 400;
	letter-spacing: 0.75px;
}

.heading-11 {
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0px;
}

.intro-paragraph {
	margin-bottom: 0.75rem;
	color: #242424;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
}

.horizontal-divider {
	height: 1px;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	background-color: rgba(92, 92, 92, 0.15);
	color: #003c43;
}

.heading-12 {
	color: #092c56;
	font-weight: 400;
}

.heading-13 {
	color: #092c56;
	font-weight: 400;
}

.heading-14 {
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0px;
}

.h4-heading {
	margin-bottom: 1rem;
	color: #163b68;
	font-size: 1.875rem;
	font-weight: 400;
	letter-spacing: 0.75px;
}

.list-text {
	color: #242424;
	font-size: 1rem;
}

.block-quote {
	margin-bottom: 2rem;
	padding: 1rem 1.5rem;
	border-left-color: #f1ad02;
	background-color: transparent;
	color: #5c5c5c;
	font-size: 1.15rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.list {
	margin-bottom: 1rem;
}

.secondary-button {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 1rem;
	margin-left: 0rem;
	padding: 0.85rem 1.5rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 1px;
	border-color: #fff;
	border-radius: 3px;
	background-color: #1e6295;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 350ms ease, border-color 350ms ease-out;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	text-decoration: none;
}

.secondary-button:hover {
	border-style: none;
	border-color: rgba(255, 255, 255, 0.3);
	background-color: #0d4c7b;
	background-position: 85% 50%;
}

.secondary-button.alert-close {
	margin-right: 0rem;
}

.tertiary-button {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 0.85rem 1.5rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 1px;
	border-color: #fff;
	border-radius: 3px;
	background-color: #5c5c5c;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 350ms ease, border-color 350ms ease-out;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	text-decoration: none;
}

.tertiary-button:hover {
	border-style: none;
	border-color: rgba(255, 255, 255, 0.3);
	background-color: #474747;
	background-position: 85% 50%;
}

.main-content-col {
	padding-left: 0px;
}

.right-sidebar-col {
	padding-right: 0px;
}

.sidebar-section-wrap {
	position: relative;
	margin-top: 1.15rem;
	margin-bottom: 1.15rem;
	margin-left: 0rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar-title {
	margin-top: 0em;
	margin-bottom: 0.75em;
	color: #1e6295;
	font-size: 1.25rem;
	font-weight: 400;
}

.sidebar-list {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.25rem;
	padding-left: 0px;
	font-family: Montserrat, sans-serif;
	color: #0915f8;
	text-decoration: none;
}

.right-sidebar-link {
	border-bottom: 1px solid rgba(22, 59, 104, 0.15);
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	line-height: 1.15rem;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-decoration: none;
}

.right-sidebar-link:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.right-sidebar-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.right-sidebar-wrapper {
	padding-left: 1.5rem;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.4rem 0.125rem 0.4rem 0.85rem;
	border-left: 2px solid transparent;
	background-color: transparent;
	-webkit-transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	font-size: 0.8rem;
	line-height: 1.375em;
	font-weight: 400;
	letter-spacing: 0.03em;
	text-decoration: none;
}

.left-nav-nested-list-link:hover {
	border-left-color: #1e6295;
	background-color: rgba(30, 98, 149, 0.06);
	-webkit-transform: translate(6px, 0px);
	-ms-transform: translate(6px, 0px);
	transform: translate(6px, 0px);
	color: #4d4c4c;
}

.left-nav-nested-list-link.w--current {
	margin-left: 0.5rem;
	padding-right: 0rem;
	padding-left: 0.35rem;
	border-left-color: #fcb31c;
	background-color: rgba(30, 98, 149, 0.09);
	color: #163b68;
	font-weight: 700;
	letter-spacing: 0.3px;
}

.left-nav-nested-list-link.great-grandchild-link {
	width: 100%;
	margin-left: 1rem;
	padding-right: 0.125rem;
	padding-left: 0.5rem;
	font-size: 0.75rem;
}

.left-nav-nested-list-link.great-grandchild-link:hover {
	border-left-color: #163b68;
	background-color: rgba(30, 98, 149, 0.05);
}

.left-nav-nested-list-link.great-grandchild-link.w--current:hover {
	border-left-color: #fcb31c;
	background-color: rgba(30, 98, 149, 0.09);
}

.left-nav-nested-list-link.great-grandchild-link.current.w--current {
	width: 100%;
	margin-left: 0rem;
	padding-left: 0.35rem;
	border-left-style: solid;
	border-left-color: #fcb31c;
	background-color: rgba(30, 98, 149, 0.09);
	color: #163b68;
	font-weight: 700;
}

.left-nav-nested-list-link.great-grandchild-link.margin-indent {
	margin-left: 1rem;
	padding-left: 0.5rem;
}

.left-nav-nested-list-link.great-grandchild-link.margin-indent:hover {
	background-color: rgba(30, 98, 149, 0.05);
	color: #163b68;
}

.left-nav-nested-list-link.great-grandchild-link.margin-indent.w--current {
	border-left-color: #fcb31c;
	background-color: rgba(30, 98, 149, 0.08);
	color: #163b68;
	font-weight: 700;
}

.left-nav-nested-list-link.great-great-grandchild-link {
	margin-left: 2rem;
	padding-left: 0.5rem;
	border-left-style: none;
	background-color: rgba(30, 98, 149, 0.04);
	font-size: 0.75rem;
}

.left-nav-nested-list-link.great-great-grandchild-link:hover {
	border-left-style: solid;
	border-left-color: #163b68;
	color: #163b68;
}

.left-nav-nested-list-link.great-great-grandchild-link.w--current {
	width: 100%;
	margin-left: 2rem;
	padding-right: 0.125rem;
	padding-left: 0.5rem;
	border-left-style: solid;
	border-left-color: transparent;
	background-color: rgba(30, 98, 149, 0.04);
	font-size: 0.75rem;
	font-weight: 400;
}

.left-nav-nested-list-link.great-great-grandchild-link.w--current:hover {
	border-left-color: #163b68;
}

.left-nav-nested-list-item-wrapper {
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	border-bottom: 1px none #000;
	background-color: transparent;
}

.left-nav-nested-list-item-wrapper.great-grandchild-wrapper {
	padding-top: 3px;
	padding-bottom: 0px;
	border-bottom-style: solid;
	border-bottom-color: hsla(0, 0%, 43.1%, 0.15);
}

.left-nav-nested-list-item-wrapper.great-great-grandchild {
	margin-top: 3px;
}

.left-nav-list-col-item {
	margin-bottom: 0px;
	padding-top: 3px;
	padding-bottom: 3px;
	border-top: 1px none hsla(0, 0%, 43.1%, 0.15);
	border-bottom: 1px solid hsla(0, 0%, 43.1%, 0.15);
}

.left-nav-list-col-item.grandchild-wrapper {
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 0rem;
	padding-top: 0px;
	padding-bottom: 0px;
	border-left: 1px none #fcb31c;
	border-top-style: none;
	border-bottom-style: none;
	background-color: transparent;
}

.left-nav-list-col-item.no-top-border {
	padding-top: 3px;
	padding-bottom: 3px;
	border-left: 1px none hsla(0, 0%, 43.3%, 0.25);
	border-top-style: none;
	background-color: transparent;
}

.left-nav-list-col-item.gray-bg {
	border-top-style: none;
	background-color: rgba(30, 98, 149, 0.04);
}

.left-nav-list-col-item.gray-bg.top-border {
	border-top-style: solid;
}

.left-nav-list-col-item.first-child-link {
	border-top-style: solid;
}

.left-nav-list-col-item.great-great-grandchild {
	padding-top: 3px;
	padding-bottom: 3px;
	border-top-style: solid;
	border-bottom-style: none;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
}

.left-nav-nested-list {
	display: block;
	overflow: hidden;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px 0em;
	border-top: 1px none #e6e6e6;
	border-bottom: 1px none #000;
	background-color: transparent;
}

.left-nav-link-main {
	display: block;
	padding: 0.4rem 0.75rem 0.4rem 0.35rem;
	border-left: 2px solid transparent;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/arrow-medium.svg');
	background-position: -20px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 0.3px;
	text-decoration: none;
}

.left-nav-link-main:hover {
	border-left: 2px solid #fcb31c;
	background-image: none;
	-webkit-transform: translate(6px, 0px);
	-ms-transform: translate(6px, 0px);
	transform: translate(6px, 0px);
	color: #163b68;
}

.left-nav-link-main.w--current {
	margin-left: 0rem;
	padding-left: 0.35rem;
	border-left: 2px solid #fcb31c;
	background-color: rgba(30, 98, 149, 0.09);
	background-image: none;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #163b68;
	font-weight: 700;
}

.left-nav-link-main.w--current:hover {
	-webkit-transform: translate(6px, 0px);
	-ms-transform: translate(6px, 0px);
	transform: translate(6px, 0px);
}

.current-nav-arrow {
	position: absolute;
	right: 0px;
	height: 27px;
}

.current-link-arrow-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px none rgba(51, 51, 51, 0.05);
	background-color: transparent;
}

.current-link-arrow-wrapper.second {
	border-bottom-style: none;
}

.current-link-arrow-wrapper.second.bg-gray {
	position: relative;
	margin-left: 1.25rem;
	padding-left: 0rem;
	background-color: transparent;
}

.current-link-arrow-wrapper.second.bg-gray:hover {
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
}

.current-link-arrow-wrapper.great-great-grandchild {
	border-top: 1px none rgba(51, 51, 51, 0.05);
	background-color: transparent;
}

.link-text {
	margin-bottom: 0.75rem;
	border-bottom: 1px solid rgba(9, 44, 86, 0.15);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #092c56;
	font-size: 1rem;
	line-height: 1.4rem;
	text-decoration: none;
}

.link-text:hover {
	border-bottom-style: none;
	color: #1e6295;
}

.link-text-wrapper {
	margin-bottom: 0.5rem;
}

.heading-15 {
	letter-spacing: 0rem;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
}

.search-button-2 {
	width: 30px;
	height: 30px;
	margin-left: -14px;
	border-radius: 20px;
	background-color: #1e6295;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/search-white-01.svg');
	background-position: 50% 50%;
	background-size: 45%;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 0;
	line-height: 15px;
}

.search-button-2:hover {
	background-color: #fcb31c;
}

.brand {
	width: 165px;
	margin-right: 1rem;
}

.search-input-form-2 {
	width: 250px;
	height: 30px;
	margin-bottom: 0px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(36, 36, 36, 0.1);
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	color: #242424;
	font-size: 0.7rem;
	letter-spacing: 0.5px;
}

.dropdown-list {
	background-color: #fff;
}

.dropdown-list.w--open {
	top: 93px;
	background-color: #163b68;
}

.dropdown-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding: 1.4rem 1.35rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.search {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	margin-bottom: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.header-nav-inner-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.25rem 3rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid hsla(0, 0%, 43.3%, 0.15);
	background-color: #fff;
}

.nav-dropdown-link {
	border-bottom: 1px solid rgba(255, 255, 255, 0.05);
	color: #fff;
	font-size: 0.9rem;
	font-weight: 400;
	letter-spacing: 0.75px;
}

.nav-dropdown-link:hover {
	background-color: #1e6295;
}

.text-block-4 {
	color: #002569;
	font-size: 0.95rem;
	letter-spacing: 0.5px;
	text-transform: none;
}

.left-nav-list-column-wrapper {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: 0rem;
	margin-right: 0.5em;
	padding-bottom: 1.5em;
	border-right: 1px none rgba(0, 0, 0, 0.08);
}

.left-nav-list-column {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
}

.controls-bar-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.controls-bar-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 2px;
	margin-right: 1em;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: #546c7a;
	text-align: left;
	text-transform: uppercase;
}

.controls-bar-item-wrap.rollover-link {
	margin-top: 0px;
	margin-right: 2px;
	margin-left: 2px;
	background-color: #919191;
	-webkit-transition: -webkit-transform 250ms ease;
	transition: -webkit-transform 250ms ease;
	transition: transform 250ms ease;
	transition: transform 250ms ease, -webkit-transform 250ms ease;
	color: #04759d;
	font-weight: 700;
}

.controls-bar-item-wrap.rollover-link:hover {
	-webkit-transform: translate(0px, -3px);
	-ms-transform: translate(0px, -3px);
	transform: translate(0px, -3px);
}

.controls-bar-item-wrap.facebook {
	margin-top: 0px;
	margin-right: 2px;
	margin-left: 2px;
	background-color: #2d76e4;
	-webkit-transition: -webkit-transform 250ms ease;
	transition: -webkit-transform 250ms ease;
	transition: transform 250ms ease;
	transition: transform 250ms ease, -webkit-transform 250ms ease;
	color: #04759d;
	font-weight: 700;
}

.controls-bar-item-wrap.facebook:hover {
	-webkit-transform: translate(0px, -3px);
	-ms-transform: translate(0px, -3px);
	transform: translate(0px, -3px);
}

.controls-bar-item-wrap.twitter {
	margin-top: 0px;
	margin-right: 2px;
	margin-left: 2px;
	background-color: #479fe8;
	-webkit-transition: -webkit-transform 250ms ease;
	transition: -webkit-transform 250ms ease;
	transition: transform 250ms ease;
	transition: transform 250ms ease, -webkit-transform 250ms ease;
	color: #04759d;
	font-weight: 700;
}

.controls-bar-item-wrap.twitter:hover {
	-webkit-transform: translate(0px, -3px);
	-ms-transform: translate(0px, -3px);
	transform: translate(0px, -3px);
}

.controls-bar-item-wrap.share {
	margin-top: 0px;
	margin-right: 2px;
	margin-left: 2px;
	background-color: #ff6550;
	-webkit-transition: -webkit-transform 250ms ease;
	transition: -webkit-transform 250ms ease;
	transition: transform 250ms ease;
	transition: transform 250ms ease, -webkit-transform 250ms ease;
	color: #04759d;
	font-weight: 700;
}

.controls-bar-item-wrap.share:hover {
	-webkit-transform: translate(0px, -3px);
	-ms-transform: translate(0px, -3px);
	transform: translate(0px, -3px);
}

.controls-bar-item-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 20px;
	height: 20px;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	background-position: 50% 50%;
	background-size: 60% 60%;
	background-repeat: no-repeat;
}

.controls-bar-item-icon.email {
	width: 22px;
	margin-right: 0em;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/email.svg');
	background-size: 70% 70%;
}

.controls-bar-item-icon.email:hover {
	border: 1px none #000;
}

.controls-bar-item-icon.print-icon {
	width: 22px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/printer.svg');
	background-size: 65%;
}

.controls-bar-item-icon.facebook-icon {
	width: 22px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/white-fb-icon.svg');
	background-position: 50% 50%;
	background-size: 70%;
}

.controls-bar-item-icon.twitter-icon {
	width: 22px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/white-twitter-icon.svg');
	background-position: 50% 50%;
	background-size: 75%;
}

.controls-bar-item-icon.plus-icon {
	width: 22px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/plus-icon.svg');
	background-position: 50% 50%;
	background-size: 60%;
}

.alert-button-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-top: 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.exit-icon-wrapper {
	position: absolute;
	left: auto;
	top: 0.5rem;
	right: 0.5rem;
	bottom: auto;
	z-index: 100;
	cursor: pointer;
}

.alert-pop-up-section {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: block;
	width: 600px;
	height: 475px;
	margin: auto;
	border-top: 5px none #c5272a;
	border-radius: 0px;
	background-color: #fff;
}

.exit-icon {
	padding: 12px;
	opacity: 0.65;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.exit-icon:hover {
	opacity: 1;
}

.alert-pop-up-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 4px solid #fcb31c;
	border-bottom: 5px none #fcb31c;
}

.alert-pop-up-wrapper {
	position: fixed;
	top: 0px;
	z-index: 5000;
	display: block;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background-color: rgba(1, 1, 1, 0.45);
}

.alert-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding: 4rem 2.15rem 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 5px none #fcb31c;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 96.5%, 0.98)), to(hsla(0, 0%, 96.5%, 0.98)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 96.5%, 0.98), hsla(0, 0%, 96.5%, 0.98));
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	position: relative;
}

.exit-icon-div {
	position: absolute;
	left: auto;
	top: 1rem;
	right: 3rem;
	bottom: auto;
	width: 25px;
	height: 25px;
	border-bottom-style: none;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/exit-icon-white.svg');
	background-position: 50% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition-property: none;
	transition-property: none;
	text-decoration: none;
}

.exit-icon-div:hover {
	opacity: 1;
}

.gdrp-section {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000000000;
	display: block;
	min-height: 125px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 5px solid #fcb31c;
	background-color: #092c56;
}

.cookie-text-para {
	width: 66.66%;
	padding-right: 1rem;
	padding-left: 1rem;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.gdrp-container {
	height: 100%;
	max-width: 1280px;
	min-height: 100%;
	padding-right: 20px;
	padding-left: 20px;
}

.gdrp-container.footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.gdrp-container.footer.gdrp {
	position: relative;
	max-width: none;
	padding: 3rem;
}

.cookie-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	min-width: 425px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cookies-button {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 1rem;
	border-style: solid;
	border-width: 2px;
	border-color: #5d5d5d;
	border-radius: 5px;
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.65rem;
	font-weight: 500;
	letter-spacing: 0em;
	text-decoration: none;
	text-transform: uppercase;
}

.cookies-button:hover {
	border-color: #272727;
	background-color: #272727;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.37);
}

.cookies-button.underline {
	margin-right: 1.5rem;
	padding: 0px;
	border-style: none none solid;
	border-bottom-width: 1px;
	border-bottom-color: hsla(0, 0%, 100%, 0.2);
	border-radius: 0px;
	background-color: transparent;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 0.5px;
	text-transform: none;
}

.cookies-button.underline:hover {
	border-bottom-color: #fff;
	color: #fff;
}

.gdrp-section-icon {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000000000;
	display: block;
	min-height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px none #d3d3d3;
	background-color: #fff;
	box-shadow: 0 -13px 0 0 rgba(179, 182, 183, 0.75);
}

.gdrp-section-icon.just-icon {
	z-index: 100000000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: transparent;
	box-shadow: none;
}

.open-gdrp-icon {
	width: 50px;
	height: 50px;
	margin-right: 4rem;
	padding-right: 0px;
	border-bottom-style: none;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/gear-icon-yellow.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition-property: none;
	transition-property: none;
	text-decoration: none;
}

.open-gdrp-icon:hover {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/gear-icon-dark-blue-01.svg');
	background-size: contain;
}

.top-header-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-left: 1px solid rgba(255, 255, 255, 0.5);
	cursor: pointer;
}

@media screen and (max-width: 991px) {
	h2 {
		font-size: 1.75rem;
	}

	.footer-left-content-wrap {
		width: 100%;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.signoff-left-wrap {
		width: 100%;
		margin-bottom: 1.5rem;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		text-align: center;
	}

	.footer-right-content-wrap {
		width: 100%;
		padding-top: 1rem;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
		padding-right: 1.5rem;
		text-align: center;
	}

	.cb-static.footer-cb-left {
		width: 100%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.home-content-wrapper {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container {
		max-width: 991px;
	}

	.container.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 1.5rem 6%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.container.footer-content-container {
		padding: 1.5rem 6%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		border: 1px none #000;
	}

	.copyright-paragraph {
		margin-top: 0rem;
	}

	.footer-nav-list {
		margin-bottom: 0rem;
	}

	.navbar {
		background-color: transparent;
	}

	.search-bar {
		top: 0px;
		display: none;
		border-bottom: 1px solid hsla(0, 0%, 43.3%, 0.15);
	}

	.search-bar-wrap {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.search-input {
		margin-right: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
		font-size: 0.85rem;
		font-weight: 400;
		letter-spacing: 0.5px;
	}

	.search-input-exit {
		display: block;
		width: auto;
		margin-right: 0rem;
		padding-right: 0px;
	}

	.slider {
		height: 35vh;
	}

	.alert-header-content-wrapper {
		margin-right: 0em;
		margin-left: 0em;
		padding: 0.75rem 0em;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.alert-box-close-button {
		right: 2rem;
		margin-right: 0rem;
		padding-right: 0px;
	}

	.alert-header-container {
		padding-right: 2rem;
		padding-left: 2rem;
		border: 1px none #000;
	}

	.social-media-link-block {
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}

	.social-media-link-block:hover {
		border-radius: 0%;
	}

	.news-cb {
		margin-bottom: 1rem;
	}

	.news-cb.last {
		margin-right: 0rem;
		margin-bottom: 2rem;
	}

	.paragraph.centered {
		margin-bottom: 0rem;
		font-size: 0.9rem;
		text-align: left;
	}

	.paragraph.centered.about {
		margin-bottom: 1rem;
		text-align: center;
	}

	.paragraph.centered.alert {
		margin-bottom: 1rem;
		text-align: center;
	}

	.paragraph.link {
		margin-top: 0.3rem;
	}

	.news-wrap {
		margin-bottom: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.news-cb-inner-wrapper.first {
		height: auto;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.news-title {
		margin-top: 0rem;
		font-size: 1.25rem;
	}

	.h2-heading {
		margin-bottom: 0rem;
		border-bottom-style: none;
	}

	.top-header-nav-wrapper {
		padding: 0.5rem 2rem;
	}

	.search-section {
		display: block;
		margin-right: 2rem;
		margin-left: auto;
	}

	.cb-feature {
		width: 100%;
		margin-bottom: 2rem;
		border-bottom: 1px solid rgba(22, 59, 104, 0.1);
	}

	.cb-feature.twitter {
		width: 100%;
		margin-bottom: 0rem;
		border-bottom-style: none;
	}

	.cb-feature.events {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 2rem;
		margin-left: 0rem;
		border-bottom-style: solid;
	}

	.footer-middle-content-wrap {
		width: 100%;
		padding-top: 1rem;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.signoff-middle-wrap {
		width: 100%;
		margin-bottom: 1.5rem;
	}

	.slider-content {
		padding-top: 0px;
		padding-bottom: 0%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.heading-3 {
		max-width: 650px;
		font-size: 2.25rem;
		line-height: 2.5rem;
	}

	.dropdown {
		display: none;
	}

	.content-wrapper {
		padding: 4rem 6%;
	}

	.content-wrapper.featured-section {
		padding-top: 4rem;
		padding-bottom: 4rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		background-image: none;
	}

	.content-wrapper.about-section {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.alert-cb-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.alert-cb {
		height: 90px;
	}

	.alert-cb._2 {
		height: 90px;
		margin-top: 1rem;
		margin-left: 0rem;
	}

	.icon-img-wrap {
		width: 20%;
	}

	.inner-cb-link {
		width: 80%;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
	}

	.cb-text.date {
		margin-top: 1rem;
	}

	.cb-text.date._1 {
		margin-top: 0.3rem;
	}

	.heading-4 {
		max-width: none;
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	.alert-img {
		width: 45px;
	}

	.cb-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cb-feature-heading-wrapper {
		margin-bottom: 1.5rem;
	}

	.calendar-date-wrap {
		width: 15%;
	}

	.calendar-wrap {
		margin-bottom: 2rem;
	}

	.text-block-3 {
		font-size: 0.8rem;
	}

	.heading-title-wrap {
		margin-bottom: 0rem;
	}

	.button-wrapper {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.fb-embed {
		display: block;
	}

	.inner-cb-wrap {
		padding-left: 0rem;
	}

	.arrow-wrapper {
		padding-top: 0rem;
	}

	.section-arrow {
		bottom: -62px;
	}

	.cb-inner-wrapper {
		width: 85%;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.contracts-img {
		width: 55px;
	}

	.header-nav-wrappper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-top: 1px none #000;
		border-bottom: 1px solid rgba(77, 76, 76, 0.25);
	}

	.two-part-mobile-nav-wrapper.top {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-top-style: none;
		border-top-color: rgba(77, 76, 76, 0.25);
		border-bottom-style: solid;
		border-bottom-color: hsla(0, 0%, 100%, 0.1);
		font-family: Montserrat, sans-serif;
	}

	.two-part-mobile-nav-wrapper.top:hover {
		background-color: #1e6295;
	}

	.two-part-mobile-nav-wrapper.top {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-top-style: none;
		border-top-color: rgba(77, 76, 76, 0.25);
		border-bottom-style: solid;
		border-bottom-color: hsla(0, 0%, 100%, 0.1);
		font-family: Montserrat, sans-serif;
	}

	.two-part-mobile-nav-wrapper.top:hover {
		background-color: #1e6295;
	}

	.nav-link {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}

	.nav-link.mobile {
		display: block;
		width: 88%;
		padding: 20px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		border-bottom-color: hsla(0, 0%, 100%, 0.1);
		background-color: transparent;
		font-family: 'Open Sans', sans-serif;
		color: #fff;
		font-size: 0.9rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.nav-link.mobile:hover {
		background-color: transparent;
		background-image: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.nav-link.mobile.w--current {
		box-shadow: inset 6px 0 0 0 #f9b604;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		color: #f9b604;
	}

	.mobile-arrow {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
		font-family: Montserrat, sans-serif;
		color: #fff;
		font-size: 0.85rem;
		line-height: 1em;
		font-weight: 500;
	}

	.slider-section-wrapper {
		margin-top: 100px;
	}

	.inside-content-background {
		max-width: 100%;
		margin-top: 0px;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.breadcrumb-list {
		margin-bottom: 0.5rem;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-page-header-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.left-nav-list-content-wrapper {
		overflow: hidden;
		height: 0px;
	}

	.main-content-wrap {
		padding-bottom: 1.5rem;
	}

	.main-content-wrap.no-sidebars {
		padding-right: 0rem;
		padding-bottom: 0rem;
	}

	.inside-page-header-section {
		min-height: 60px;
	}

	.main-content-col {
		padding-left: 0px;
	}

	.right-sidebar-col {
		padding-right: 0px;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.right-sidebar-wrapper {
		padding-left: 1.5rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.menu-button {
		padding: 0px 0rem 0px 0px;
	}

	.menu-button.w--open {
		border-radius: 3px;
		background-color: transparent;
		color: #163b68;
	}

	.nav-menu {
		background-color: #163b68;
	}

	.search-button-2 {
		position: relative;
		z-index: 1100;
		margin-left: -14px;
		background-color: #1e6295;
		color: #333;
	}

	.search-button-2:hover {
		background-color: #9ed3e6;
	}

	.icon-8 {
		border-radius: 3px;
		color: #163b68;
		font-size: 2rem;
	}

	.search-input-form-2 {
		position: relative;
		z-index: 1000;
		display: none;
		overflow: hidden;
		width: 0px;
		margin-bottom: 0px;
		opacity: 0;
	}

	.search {
		width: auto;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px solid rgba(77, 76, 76, 0.25);
		background-color: rgba(30, 98, 149, 0.1);
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: #1e6295;
	}

	.header-nav-inner-content {
		width: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
		border-bottom-color: hsla(0, 0%, 43.3%, 0.15);
	}

	.left-nav-list-column-wrapper {
		overflow: hidden;
		height: 0px;
	}

	.left-nav-list-column {
		margin-bottom: 1.5em;
	}

	.controls-bar-wrapper {
		margin-bottom: 1rem;
	}

	.controls-bar-item-wrap {
		margin-right: 2em;
		margin-left: 0em;
	}

	.alert-pop-up-wrapper {
		position: fixed;
		display: block;
	}

	.exit-icon-div {
		right: 2rem;
	}

	.gdrp-section {
		display: block;
	}

	.cookie-text-para {
		width: 90%;
		margin-bottom: 1rem;
		padding-left: 0rem;
		font-size: 0.8rem;
		line-height: 1.25rem;
	}

	.gdrp-container.footer.gdrp {
		padding: 1rem 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cookie-div {
		width: 90%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	iframe {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media screen and (max-width: 767px) {
	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	h3 {
		font-size: 1.3125rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	.footer-left-content-wrap {
		width: 100%;
		padding-bottom: 0.5rem;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.signoff-left-wrap {
		margin-bottom: 1.5rem;
	}

	.footer-right-content-wrap {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.footer-signoff-link.adobe-link {
		margin-top: 0.5rem;
	}

	.footer-nav-item {
		margin-top: 0rem;
		margin-bottom: 0.5rem;
		padding-right: 1.5rem;
	}

	.cb-static {
		width: 33.33%;
	}

	.cb-static.footer-cb-left {
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container {
		max-width: 767px;
	}

	.container.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-top: 1.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.container.footer-content-container {
		padding-bottom: 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.copyright-paragraph {
		margin-top: 0rem;
		margin-right: 0.5rem;
	}

	.footer-nav-list {
		margin-left: 0rem;
		padding-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-signoff-link-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.search-bar {
		top: 0px;
		display: none;
	}

	.search-bar-wrap {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.search-input {
		margin-right: 0.5rem;
	}

	.slider {
		height: 30vh;
	}

	.alert-header-content-wrapper {
		margin-right: auto;
		margin-left: 0rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		font-size: 1em;
	}

	.alert-box-close-button {
		right: 1.5rem;
		margin-right: 0rem;
	}

	.alert-header-container {
		padding-right: 0px;
		padding-left: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.alert-header-text {
		padding-right: 0em;
	}

	.alert-header-inner-text {
		font-size: 0.8rem;
	}

	.social-media-link-block {
		margin-right: 0.75rem;
	}

	.social-media-link-block:hover {
		border-radius: 100%;
	}

	.social-media-link-block.twitter {
		border-style: none;
	}

	.news-cb {
		margin-right: 0rem;
	}

	.news-cb.last {
		margin-bottom: 1rem;
	}

	.paragraph.centered.about {
		font-size: 1.05rem;
		line-height: 1.4rem;
	}

	.paragraph.centered.alert {
		font-size: 1.05rem;
		line-height: 1.4rem;
	}

	.news-wrap {
		margin-bottom: 3rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.h2-heading {
		width: auto;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.top-header-nav-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.search-section {
		margin-right: 1.5rem;
	}

	.button {
		font-size: 0.8rem;
	}

	.cb-feature {
		margin-bottom: 2rem;
	}

	.cb-feature.twitter {
		width: 100%;
		margin-bottom: 0rem;
	}

	.cb-feature.events {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 3rem;
		border-bottom-style: solid;
		border-bottom-color: rgba(22, 59, 104, 0.1);
	}

	.footer-middle-content-wrap {
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px none rgba(255, 248, 225, 0.25);
	}

	.signoff-middle-wrap {
		margin-bottom: 1.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slider-content {
		padding-top: 0px;
	}

	.heading-3 {
		max-width: 500px;
		font-size: 1.75rem;
		line-height: 1.9rem;
	}

	.top-header-nav-link.first {
		margin-left: 0rem;
	}

	.content-wrapper {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.content-wrapper.featured-section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.content-wrapper.about-section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.content-wrapper.footer-cb-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.alert-cb {
		height: auto;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.alert-cb._2 {
		height: auto;
	}

	.icon-img-wrap {
		padding-top: 1rem;
		padding-bottom: 1rem;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.cb-text.announcements.yellow {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.cb-wrapper._2 {
		margin-top: 2rem;
	}

	.cb-feature-heading-wrapper {
		margin-bottom: 1.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.view-all-link {
		margin-top: 1rem;
	}

	.calendar-cb.last {
		margin-bottom: 1rem;
	}

	.calendar-date-wrap {
		width: 20%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.calendar-wrap {
		margin-bottom: 3rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.text-block-3 {
		font-size: 0.75rem;
	}

	.twitter-wrap {
		margin-bottom: 0rem;
	}

	.heading-title-wrap {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inner-cb-wrap {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.nav-link.mobile {
		width: 84%;
		padding-left: 20px;
	}

	.inside-content-background {
		margin-top: 0px;
	}

	.breadcrumb-list {
		margin-bottom: 0.5rem;
	}

	.paragraph-text {
		font-size: 0.9rem;
	}

	.inside-page-header-content-wrap {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.heading-5 {
		font-size: 1.65rem;
		line-height: 1.9rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 0px;
		margin-left: 0px;
	}

	.inside-page-header-section {
		min-height: 50px;
	}

	.heading-6 {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	.main-content-col {
		padding-right: 0px;
		padding-left: 0px;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.right-sidebar-wrapper {
		padding-left: 0rem;
	}

	.link-text {
		font-size: 0.9rem;
	}

	.brand {
		width: 125px;
		padding-left: 0px;
	}

	.mobile-menu-arrow {
		width: 16%;
		background-color: rgba(30, 98, 149, 0.1);
	}

	.header-nav-inner-content {
		padding: 0.5rem 1.5rem;
	}

	.controls-bar-item-wrap {
		margin-right: 0em;
	}

	.alert-pop-up-section {
		width: 450px;
	}

	.alert-pop-up-wrapper {
		display: block;
	}

	.cookie-text-para {
		width: 85%;
	}

	.gdrp-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.cookie-div {
		width: 85%;
	}
}

@media screen and (max-width: 479px) {
	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	.footer-left-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.signoff-left-wrap {
		margin-bottom: 1.25rem;
	}

	.footer-right-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-bottom-color: rgba(255, 248, 225, 0.25);
	}

	.footer-signoff-link {
		margin-right: 0.25rem;
		margin-bottom: 0.5rem;
		margin-left: 0.25rem;
	}

	.footer-signoff-link.adobe-link {
		margin-top: 0rem;
		margin-bottom: 0.5rem;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.cb-static.footer-cb-left {
		width: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container {
		max-width: none;
	}

	.container.signoff-container {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.container.footer-content-container {
		text-align: center;
	}

	.copyright-paragraph {
		margin-right: 0rem;
	}

	.footer-nav-list {
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.search-bar {
		top: 0px;
		display: none;
	}

	.search-bar-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.search-input {
		margin-right: 1rem;
	}

	.search-input-exit {
		margin-right: 0.25rem;
	}

	.slider {
		height: 40vh;
	}

	.alert-header-content-wrapper {
		margin-right: auto;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.alert-box-close-button {
		right: 1rem;
		margin-top: 0rem;
	}

	.alert-header-container {
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.alert-header-text {
		padding-right: 0em;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.alert-header-inner-text {
		max-width: none;
		margin-right: 2rem;
		margin-bottom: 0.5rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		font-size: 0.8rem;
		line-height: 1.15rem;
	}

	.news-cb {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.paragraph {
		text-align: left;
	}

	.paragraph.centered {
		font-size: 0.85rem;
		line-height: 20px;
		text-align: center;
	}

	.paragraph.centered.about {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	.paragraph.centered.alert {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	.paragraph.link {
		font-size: 0.85rem;
		text-align: left;
	}

	.news-cb-inner-wrapper {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.news-title {
		font-size: 1.15rem;
		line-height: 1.4rem;
		text-align: left;
	}

	.news-inner-cb {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.h2-heading {
		width: auto;
		margin-right: 0px;
		margin-bottom: 0rem;
		margin-left: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		font-size: 1.75rem;
		line-height: 1.9rem;
		text-align: center;
	}

	.top-header-nav-wrapper {
		padding-top: 1rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.search-section {
		margin-right: 1rem;
		margin-bottom: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.cb-feature.events {
		margin-bottom: 3rem;
	}

	.footer-middle-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-bottom-color: rgba(255, 248, 225, 0.25);
	}

	.signoff-middle-wrap {
		margin-bottom: 1rem;
	}

	.slider-content {
		padding-top: 0px;
	}

	.heading-3 {
		max-width: none;
		padding-right: 2.25rem;
		padding-left: 2.25rem;
		font-size: 1.5rem;
		line-height: 1.65rem;
		text-align: center;
	}

	.top-header-nav-link {
		margin-right: 0.25rem;
		margin-bottom: 0rem;
		margin-left: 1rem;
		font-size: 0.75rem;
		text-align: center;
	}

	.top-header-nav-link.last {
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.top-header-nav-link.first {
		margin-bottom: 0.5rem;
		font-size: 0.75rem;
	}

	.top-header-nav-link.translate {
		text-align: center;
	}

	.content-wrapper {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.content-wrapper.footer-cb-section {
		padding-right: 6%;
		padding-left: 6%;
	}

	.alert-cb {
		height: auto;
	}

	.alert-cb._2 {
		height: auto;
	}

	.icon-img-wrap {
		width: 22%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.inner-cb-link {
		width: 78%;
		padding-top: 1rem;
		padding-right: 1rem;
	}

	.cb-text.date {
		letter-spacing: 0.5px;
	}

	.cb-text.date._1 {
		font-size: 0.7rem;
		letter-spacing: 0.5px;
	}

	.heading-4 {
		font-size: 1.15rem;
		line-height: 1.25rem;
	}

	.alert-img {
		width: 30px;
	}

	.icon-5 {
		margin-left: 0.25rem;
	}

	.icon-6 {
		margin-right: 0.25rem;
	}

	.cb-wrapper._2 {
		margin-top: 3rem;
	}

	.dropdown-icon.translate {
		background-size: 8px;
	}

	.header-underline {
		display: none;
	}

	.calendar-date-wrap {
		width: 22%;
	}

	.calendar-cb-text {
		margin-bottom: 0rem;
		font-size: 0.8rem;
	}

	.calendar-cb-text._2 {
		font-size: 1.75rem;
	}

	.text-block-3 {
		font-size: 0.7rem;
	}

	.slider-right-arrow {
		top: auto;
	}

	.calendar-inner-cb {
		width: 100%;
		padding-left: 1rem;
	}

	.div-block-98 {
		width: 18px;
		height: 18px;
	}

	.div-block-98.blue {
		width: 18px;
		height: 18px;
	}

	.inner-cb-wrap {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.arrow-wrapper {
		padding-top: 0.5rem;
	}

	.section-arrow.alert-pop-up {
		bottom: 346px;
	}

	.cb-inner-wrapper {
		width: 78%;
		padding-right: 0.5rem;
	}

	.contracts-img {
		width: 40px;
	}

	.nav-link.mobile {
		width: 80%;
	}

	.italic-text {
		text-align: left;
	}

	.left-slider-arrow {
		top: auto;
	}

	.slider-section-wrapper {
		margin-top: 115px;
	}

	.inside-content-background {
		margin-top: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.breadcrumb-list {
		margin-bottom: 1rem;
	}

	.breadcrumb-list-item {
		margin-top: 0.3rem;
		margin-bottom: 0.35rem;
	}

	.inside-page-header-content-wrap {
		padding-bottom: 0.5rem;
	}

	.heading-5 {
		line-height: 1.8rem;
	}

	.heading-6 {
		margin-top: 0.5rem;
		line-height: 2.25rem;
	}

	.intro-paragraph {
		line-height: 1.5rem;
	}

	.secondary-button {
		margin-bottom: 1rem;
	}

	.secondary-button.cookies-btn {
		margin-right: 0rem;
	}

	.main-content-col {
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-sidebar-link {
		border-bottom-width: 0.5px;
	}

	.search-button-2:hover {
		background-color: #fcb31c;
	}

	.brand {
		width: 125px;
	}

	.search-input-form-2 {
		top: auto;
		right: auto;
	}

	.search {
		width: auto;
	}

	.mobile-menu-arrow {
		position: relative;
		width: 20%;
	}

	.header-nav-inner-content {
		padding: 0.75rem 1rem;
	}

	.alert-button-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.alert-pop-up-section {
		width: 265px;
		height: 500px;
	}

	.alert-pop-up-wrapper {
		display: block;
	}

	.gdrp-section {
		display: block;
	}

	.cookie-text-para {
		margin-bottom: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
		font-size: 0.8rem;
		line-height: 1.075rem;
		text-align: center;
	}

	.gdrp-container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.gdrp-container.footer {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.gdrp-container.footer.gdrp {
		padding-top: 3rem;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.cookie-div {
		width: 100%;
		min-width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cookies-button {
		margin-top: 1rem;
		margin-right: 0rem;
	}

	.cookies-button.underline {
		margin-right: 0rem;
		margin-bottom: 1rem;
	}

	.gdrp-section-icon.just-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.top-header-wrapper {
		margin-bottom: 0.5rem;
		margin-left: 1rem;
		padding-left: 0rem;
	}
}


/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Webflow ***********************/
/*************************************************/
@media (min-width: 992px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="4b0dedaf-d1af-1cad-45f7-9ede2d0ea7db"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="750e7b17-6872-3a95-4ce5-fd6dae93124a"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="750e7b17-6872-3a95-4ce5-fd6dae93124b"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="cb1e037c-b2f8-e979-3997-9375c6fa3b46"] {
		opacity: 0;
		-webkit-transform: translate3d(0, 48px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(0, 48px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(0, 48px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(0, 48px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="7920706c-d5c5-662d-47ae-c992c03034c5"] {
		-webkit-transform: translate3d(0, 48px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-moz-transform: translate3d(0, 48px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		-ms-transform: translate3d(0, 48px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		transform: translate3d(0, 48px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="618b0d6b-1c28-c876-f018-c14baaef837c"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="838a9ae1-0925-3e8a-ca41-c767fdbadd32"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="3e801a5f-45d4-76d6-9fd1-8719569f459f"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="4ab91b47-72a9-a112-9e5e-e05512633d72"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="4ab91b47-72a9-a112-9e5e-e05512633d81"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="c635e001-cb7b-0f45-4668-89d32f387c1f"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="73ad9ea6-fbca-80b3-bbff-bc02d94ccf58"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="ce505746-0e7b-574a-aa46-4963b52de3f4"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="ce505746-0e7b-574a-aa46-4963b52de3f7"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="17f3d786-f663-0192-278d-28017cc7a957"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="ab36d125-df83-6cf7-ea04-388ee6f7a2cc"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="dba58a24-2d5c-cea1-9e9b-bba0965f48f1"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="5a5b4c24-4dfa-2369-fd4d-378c73feaf3e"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="4ab91b47-72a9-a112-9e5e-e05512633d76"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="e644d21d-b19c-34cd-dc94-4d1367563abb"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="ce505746-0e7b-574a-aa46-4963b52de41e"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="ce505746-0e7b-574a-aa46-4963b52de422"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="ce505746-0e7b-574a-aa46-4963b52de42d"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="ce505746-0e7b-574a-aa46-4963b52de438"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="a1f4d286-07e8-43d7-a176-9d4662c44b3e"] {
		opacity: 0;
	}
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	border: 0px !important;
	padding: 0px !important;
	background: none !important;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
	background-color: #fff !important;
	padding: 5px 10px !important;
	color: #333 !important;
}

.alert-header-text p,
.alert-header-text li,
.alert-header-text a,
.alert-header-text td,
.alert-header-text span {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.alert-header-text p:last-child {
	margin-bottom: 0px;
}

.alert-header-text a {
	border-style: none none solid;
	border-width: 1px;
	border-color: #fafafa #fafafa hsla(0, 0%, 98%, 0.5);
	text-decoration: none;
	transition: border-color 350ms ease;
}

.alert-header-text a:hover {
	border-bottom-color: #fafafa;
}

.alert-box-close-button {
	border: 0px;
	background-color: transparent;
}

.search-button-2.desktop {
	display: inline-block;
}

.search-button-2.mobile {
	display: none;
}

.alert-header-section-wrapper,
.alert-pop-up-wrapper {
	display: none;
}

.alert-pop-up-wrapper h2 {
	margin-top: 0rem;
	margin-bottom: 1rem;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	font-size: 2.25rem;
	line-height: 2rem;
	font-weight: 400;
	letter-spacing: 0.75px;
}

.paragraph.centered p {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-weight: 400;
	max-width: 800px;
	font-size: 1.15rem;
	line-height: 1.5rem;
	text-align: center;
	display: inline-block;
}

.top-header-wrapper {
	position: relative;
}

.google-translate-container {
	display: none;
	position: absolute !important;
	right: 0;
	left: auto;
	top: 100%;
	overflow: hidden;
	min-width: 100%;
	padding-top: 11px;
	z-index: 2000;
}

.google-translate-container-inner {
	background-color: #dddddd;
	min-width: 100%;
	padding: 10px;
}

.top-header-wrapper:hover .google-translate-container {
	display: block;
}

@media (min-width: 992px) {
	.search-bar {
		display: none !important;
	}

	.navbar,
	.search-section {
		transition: all 0.4s ease;
	}

	.navbar.sticky {
		transform: translate3d(0px, -30px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}

	.navbar.sticky .search-section {
		transform: translate3d(0px, 0px, 0px) scale3d(0.9, 0.9, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}
}

@media (max-width: 991px) {
	.search-button-2.mobile {
		display: inline-block;
	}

	.search-button-2.desktop {
		display: none;
	}

	.alert-pop-up-wrapper h2 {
		margin-bottom: 1rem;
	}


	.google-translate-container {
		padding-top: 8px;
	}
}

@media screen and (max-width: 767px) {
	.paragraph.centered p {
		font-size: 1.05rem;
		line-height: 1.4rem;
	}
}

@media screen and (max-width: 571px) {
	.google-translate-container {
		padding-top: 15px;
	}
}

@media screen and (max-width: 479px) {
	.alert-pop-up-wrapper h2 {
		font-size: 1.75rem;
		line-height: 1.9rem;
		text-align: center;
	}

	.paragraph.centered p {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	.google-translate-container {
		padding-top: 16px;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu ul,
.accesible-navigation-menu ul li {
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px;
}

.accesible-navigation-menu ul li.hasChildren a.dropdown-toggle::after {
	display: block;
	content: "";
	width: 10px;
	height: 10px;
	margin-left: 0.5rem;
	padding-left: 0px;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/navigation-blue-arrow-icon.svg');
	background-position: 50% 50%;
	background-size: auto;
	background-repeat: no-repeat;
	transition: all 0.4s ease;
}

.accesible-navigation-menu ul li.hasChildren:hover a.dropdown-toggle::after {
	transform: rotateZ(-180deg);
}

.accesible-navigation-menu ul li a.dropdown-toggle {
	text-decoration: none;
}

.accesible-navigation-menu li.dropdown .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.dropdown .container-wrapper ul {
	display: none;
	padding: 0;
	margin: 0px;
	list-style-type: none;
	min-width: 230px;
	background-color: #163b68;
	box-shadow: 1px 1px 10px 0 rgb(0 0 0 / 5%);
}

.accesible-navigation-menu li.dropdown .container-wrapper ul a {
	border-bottom: 1px solid rgba(255, 255, 255, 0.05);
	color: #fff;
	font-size: 0.9rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	padding: 10px 20px;
	display: inline-block;
	width: 100%;
	text-decoration: none;
}

.accesible-navigation-menu li.dropdown .container-wrapper ul a:hover,
.accesible-navigation-menu li.dropdown .container-wrapper ul a.w--current {
	background-color: #1e6295;
	color: #fff;
}

.accesible-navigation-menu li.dropdown.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
	padding-top: 18px;
}

.accesible-navigation-menu li.dropdown.open .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu,
.mobile-navigation-menu .mm-panels,
.mobile-navigation-menu .mm-panel,
.mobile-navigation-menu .mm-navbar {
	background-color: #163b68 !important;
}

.mobile-navigation-menu li .nav-link,
.mobile-navigation-menu li .mm-listitem__btn,
.mm-navbar__title {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	padding: 20px;
	text-align: left;
}

.mobile-navigation-menu li:hover,
.mobile-navigation-menu li.active,
.mm-listitem_selected > .mm-listitem__text {
	background-color: #1e6295;
}

.mm-navbar__title > span {
	color: #fff;
}

.mm-listitem:after {
	left: 0;
	border-color: #1e426d;
}

.mm-navbar {
	border-color: #1e426d;
}

.mm-listitem__btn {
	background-color: rgba(30, 98, 149, 0.1);
	border-color: #1e426d;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mm-btn:after,
.mm-btn:before {
	border-color: #fff;
}

.mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title:last-child {
	justify-content: flex-start;
}


/********************* Homepage *********************/
/*****************************************************/
.heading-4 a,
.heading-4 a:hover,
.h2-heading a,
.h2-heading a:hover {
	font-weight: inherit !important;
	line-height: inherit !important;
	font-size: inherit !important;
	font-weight: inherit !important;
	color: inherit !important;
	border-bottom: 0px !important;
	letter-spacing: inherit !important;
	text-decoration: none !important;
}

.paragraph.link p {
	margin-bottom: 0px;
}

.paragraph.link:hover {
	color: inherit;
	border-bottom: 0px;
	text-decoration: none;
	margin-bottom: 0px;
}

.paragraph.link p,
.paragraph.link li,
.paragraph.link a,
.paragraph.link td,
.paragraph.link span,
.paragraph.link a:hover,
.paragraph.centered p,
.paragraph.centered li,
.paragraph.centered a,
.paragraph.centered td,
.paragraph.centered span,
.paragraph.centered a:hover,
.cb-text.date p,
.cb-text.date li,
.cb-text.date a,
.cb-text.date td,
.cb-text.date span,
.cb-text.date a:hover {
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	border-bottom: 0px;
	text-decoration: none;
}

.paragraph.link a:hover {
	border-bottom-style: none;
	color: #002569;
	text-decoration: underline;
}

.paragraph.about h2 {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	font-size: 2.25rem;
	line-height: 2rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
	margin-bottom: 1rem;
	text-transform: none;
	margin-top: 0px;
}

.heading-title-wrap {
	margin: 0px;
}

.cb-text.news-category.light-blue {
	margin: 0;
}

.paragraph.centered {
	margin-top: 0px;
}

.paragraph.centered p:last-child,
.cb-text.date p:last-child {
	margin-bottom: 0px;
}

.cb-text.announcements.yellow {
	opacity: 1 !important;
}

.h2-heading {
	margin-bottom: 1rem;
}

@media screen and (max-width: 991px) {

	.paragraph.about h2,
	.h2-heading {
		margin-bottom: 0rem;
	}
}

@media screen and (max-width: 479px) {
	.paragraph.about h2 {
		font-size: 1.75rem;
		line-height: 1.9rem;
		text-align: center;
		margin-bottom: 0;
	}
}

.cb-feature.news {
	width: 60%;
}

.news-wrap {
	position: relative;
}

#news_module.homepage_contentbox {
	display: flex;
	width: 100%;
}

#news_module.homepage_contentbox .view-all-link {
	position: absolute;
	right: 0;
	top: -55px;
}

#news_module.homepage_contentbox .news-cb-inner-wrapper.first {
	height: 100%;
}

#news_module.homepage_contentbox .news-cb:first-child {
	margin-right: 3rem;
}

@media screen and (max-width: 991px) {
	.cb-feature.news {
		width: 100%;
	}

	.news-wrap {
		margin-bottom: 1rem;
	}

	#news_module.homepage_contentbox {
		display: flex;
		flex-wrap: wrap;
	}

	#news_module.homepage_contentbox .news-cb:first-child {
		margin-right: 0;
	}

	#news_module.homepage_contentbox .news-cb {
		margin-bottom: 1rem;
		width: 100%;
		order: 2;
	}

	#news_module.homepage_contentbox .view-all-link {
		position: static;
		right: 0;
		top: 0;
		order: 0;
		display: inline-block;
		margin: 0 auto;
		margin-bottom: 2rem;
	}

	.cb-wrapper.footer-notice {
		margin-top: 0;
	}
}

.cb-wrapper.public-meetings {
	width: 50%;
	margin-right: auto;
	margin-bottom: 4rem;
	margin-left: auto;
}

.cb-wrapper.public-meetings .alert-cb.max-width {
	max-width: 600px;
	margin-right: auto;
	margin-left: auto;
}

.cb-wrapper.public-meetings .icon-img-wrap.second {
	width: 30%;
	background-color: hsla(0, 0%, 43.1%, 0.1);
	background-image: none;
	background-position: 0px 0px;
	padding: 0.5rem;
}

.cb-wrapper.public-meetings .contracts-img-copy {
	display: block;
	width: 100%;
}

.cb-wrapper.public-meetings .inner-cb-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	grid-column-gap: 0.5rem;
	background-color: #fff;
}

.cb-wrapper.public-meetings .inner-cb-link.second-copy {
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	background-color: #fafafa;
	margin-left: 7px;
}

.cb-wrapper.public-meetings .inner-cb-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.5rem 16px 0.5rem 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: auto;
}

.cb-wrapper.public-meetings .heading-4 {
	margin-top: 0px;
	margin-bottom: 0.35rem;
	color: #163b68;
	font-size: 1.3rem;
	line-height: 1.5rem;
	font-weight: 400;
	letter-spacing: 0.25px;
}

.cb-wrapper.public-meetings .cb-text.date._1 {
	margin-top: 0.5rem;
	margin-bottom: 0rem;
	color: #1e6295;
	font-size: 0.75rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
}

.cb-wrapper.public-meetings .cb-text.date._1 p {
	color: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
}

.cb-wrapper.public-meetings .arrow-wrapper {
	margin-left: 0px;
}

.section-arrow.grey {
	margin-top: -1px;
	bottom: auto;
}

@media screen and (max-width: 991px) {
	.cb-wrapper.public-meetings {
		width: 100%;
	}

	.cb-wrapper.public-meetings .cb-wrapper.public-meetings {
		width: 100%;
	}

	.cb-wrapper.public-meetings .alert-cb.max-width.new {
		height: auto;
	}

	.cb-wrapper.public-meetings .inner-cb-link {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-wrapper.public-meetings .inner-cb-wrap {
		padding-top: 0rem;
		padding-bottom: 0rem;
		line-height: 1;
	}

	.cb-wrapper.public-meetings .heading-4 {
		font-size: 1.15rem;
		line-height: 1;
	}

	.cb-wrapper.public-meetings .cb-text.date._1 {
		margin-top: 0.3rem;
	}
}

@media screen and (max-width: 767px) {
	.home-section.new {
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.cb-wrapper.public-meetings .inner-cb-wrap {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.cb-wrapper.public-meetings .icon-img-wrap.second {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

@media screen and (max-width: 479px) {
	.cb-wrapper.public-meetings .alert-cb.max-width.new {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-wrapper.public-meetings .icon-img-wrap.second {
		width: 75vw;
	}

	.cb-wrapper.public-meetings .inner-cb-link {
		width: 78%;
		padding-top: 1rem;
		padding-right: 1rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.cb-wrapper.public-meetings .inner-cb-link.second-copy {
		width: 75vw;
		margin-left: 0px;
	}

	.cb-wrapper.public-meetings .inner-cb-wrap {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.cb-wrapper.public-meetings .heading-4 {
		font-size: 1.15rem;
		line-height: 1.25rem;
	}

	.cb-wrapper.public-meetings .cb-text.date._1 {
		font-size: 0.7rem;
		letter-spacing: 0.5px;
	}
}

.grid-container .default-no-side-padding.contentbox-container {
	padding-left: 0px;
	padding-right: 0px;
}

.bordered-with-white-background a.white-bordered-cb {
	box-shadow: 0px 2px 2px 2px #e3e2e2;
	background-color: #fff;
	display: flex;
	border-radius: 5px;
	border-bottom: 0px;
	padding: 2rem 1rem;
	align-items: center;
	column-gap: 2rem;
	height: 100%;
	transition: all 0.3s ease;
}

.bordered-with-white-background a.white-bordered-cb:hover {
	transform: translate(0px, -5px);
	box-shadow: 0px 3px 3px 3px #cccccc;
}

.bordered-with-white-background a.white-bordered-cb .white-bordered-cb_image img {
	min-width: 50px;
	max-width: 70px;
	height: auto;
}

.bordered-with-white-background a.white-bordered-cb .white-bordered-cb_content {
	display: flex;
	flex-direction: column;
	height: 100%;
	row-gap: 1rem;
}

.bordered-with-white-background a.white-bordered-cb .white-bordered-cb_content .white-bordered-cb_content_title h4 {
	margin-top: 0px;
	margin-bottom: 0;
	font-size: 1.15rem;
	line-height: normal;
}

.bordered-with-white-background a.white-bordered-cb .white-bordered-cb_content .white-bordered-cb_content_text p {
	line-height: 1.1rem;
	font-size: 0.9rem;
}

.bordered-with-white-background a.white-bordered-cb .white-bordered-cb_content .white-bordered-cb_content_text p:last-child {
	margin-bottom: 0px;
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-section-wrapper .heading-3 p {
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	border-bottom: 0px;
	text-decoration: none;
}
/****************** Inside Page ******************/
/*************************************************/
.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child .breadcrumb-link {
	color: #163b68;
	font-size: 0.85rem;
	line-height: 0.6875rem;
	font-weight: 700;
	border-bottom: 0px;
	text-transform: none;
}

.left-nav-nested-list.white-bg {
	background-color: #fff;
}

.left-nav-list-col-item {
	padding-top: 0px;
	padding-bottom: 0px;
	border-top: 0;
	border-bottom: 1px solid hsla(0, 0%, 43.1%, 0.15);
}

ul.left-nav-list-column li:first-child {
	border-top: 1px solid hsla(0, 0%, 43.1%, 0.15);
}

ul.left-nav-list-column ul li:last-child {
	border-bottom: 0px;
}

.left-nav-link-main,
.left-nav-nested-list-link,
.left-nav-nested-list-link.great-grandchild-link {
	margin-bottom: 3px;
	margin-top: 3px;
	border-bottom: 0px !important;
}

.left-nav-nested-list-link {
	display: inline-block;
	width: 100%;
}

.left-nav-link-main.active {
	margin-left: 0rem;
	padding-left: 0.35rem;
	border-left: 2px solid #fcb31c;
	background-color: rgba(30, 98, 149, 0.09);
	background-image: none;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #163b68;
	font-weight: 700;
}

.left-nav-nested-list-link.active {
	margin-left: 0.5rem;
	padding-right: 0rem;
	padding-left: 0.35rem;
	border-left-color: #fcb31c;
	background-color: rgba(30, 98, 149, 0.09);
	color: #163b68;
	font-weight: 700;
	letter-spacing: 0.3px;
}

.left-nav-nested-list-link.great-grandchild-link + ul {
	padding-left: 10px;
}

.left-nav-nested-list-link.great-grandchild-link.active {
	width: calc(100% - 1.25rem);
	margin-left: 0rem;
	padding-left: 0.35rem;
	border-left-style: solid;
	border-left-color: #fcb31c;
	background-color: rgba(30, 98, 149, 0.09);
	color: #163b68;
	font-weight: 700;
	margin-left: 1.25rem;
}

.left-nav-list-col-item.last-active {
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/blue-arrow-left-nav.svg');
	background-repeat: no-repeat;
	background-position: right 4px;
	background-size: 8px 27px;
}

.left-nav-list-col-item.gray-bg.active {
	background-color: transparent;
}

.main-content-column.right-column {
	padding-right: 0px;
	padding-left: 0px;
}

.main-content-column.right-column .main-content-col {
	padding-right: 10px;
}

.main-content-column.right-column .right-sidebar-col {
	padding-left: 10px;
}

.main-content-column.no-columns {
	padding-left: 0px;
}

.inside-page-header-section.no-banner {
	height: 10rem;
}

@media (max-width: 991px) {
	.inside-page-header-section.no-banner {
		height: 7rem;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

@media (max-width: 479px) {
	.inside-page-header-section.no-banner {
		height: 10rem;
	}
}


/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-col td img {
	max-width: none;
}

.right-sidebar-col .box {
	margin-top: 1.15rem;
	margin-bottom: 1.15rem;
	margin-left: 0rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar-col .box p,
.right-sidebar-col .box li,
.right-sidebar-col .box td,
.right-sidebar-col .box a,
.right-sidebar-col .box span {
	font-size: 0.875rem;
	line-height: 1.15rem;
}

.right-sidebar-col .box p {
	margin-top: 0;
	margin-bottom: 0.75rem;
}

.right-sidebar-col .box h4,
.right-sidebar-col .box h3 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	color: #1e6295;
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.775rem;
	letter-spacing: 0.01rem;
}

.right-sidebar-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar-col .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-col .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {
	.right-sidebar-col .box {
		margin-left: 0px;
	}

	.main-content-column.right-column .right-sidebar-col {
		padding-left: 0px;
	}
}

@media screen and (max-width: 767px) {

	.right-sidebar-col .box h4,
	.right-sidebar-col .box h3 {
		line-height: 1.5em;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.inside-row a,
.cboxes a {
	border-bottom: 1px solid #828282;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	line-height: 1.15rem;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-decoration: none;
}

.inside-row a:hover,
.cboxes a:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

h1 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #163b68;
	font-size: 2.25rem;
	line-height: 3rem;
	font-weight: 400;
	letter-spacing: 0.75px;
}

h2 {
	margin-top: 1.5rem;
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0.75px;
}

h3 {
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0.4px;
}

h4 {
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0.2px;
}

h5 {
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0px;
}

h6 {
	color: #163b68;
	font-weight: 400;
	letter-spacing: 0px;
}

h1 a,
.inside-row h1 a,
.cboxes h1 a {
	border-bottom-color: #828282;
	-webkit-transition: color 350ms ease;
	transition: color 350ms ease;
	color: #092c56;
	font-size: 2.25rem;
	line-height: 2.9rem;
	letter-spacing: 0.75px;
	text-decoration: none;
}

h2 a,
.inside-row h2 a,
.cboxes h2 a {
	border-bottom: 1px solid #828282;
	border-bottom-color: #828282;
	-webkit-transition: color 350ms ease;
	transition: color 350ms ease;
	color: #092c56;
	font-size: 1.875rem;
	line-height: 2.175rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
}

h3 a,
.inside-row h3 a,
.cboxes h3 a {
	border-bottom: 1px solid #828282;
	border-bottom-color: #828282;
	-webkit-transition: color 350ms ease;
	transition: color 350ms ease;
	color: #092c56;
	font-size: 1.5rem;
	line-height: 1.975rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
}

h4 a,
.inside-row h4 a,
.cboxes h4 a {
	border-bottom: 1px solid #828282;
	border-bottom-color: #828282;
	color: #092c56;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
}

h5 a,
.inside-row h5 a,
.cboxes h5 a {
	border-bottom: 1px solid #828282;
	border-bottom-color: #828282;
	-webkit-transition: color 350ms ease;
	transition: color 350ms ease;
	color: #092c56;
	letter-spacing: 0px;
	text-decoration: none;
}

h6 a,
.inside-row h6 a,
.cboxes h6 a {
	border-bottom: 1px solid #828282;
	border-bottom-color: #828282;
	color: #163b68;
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: 400;
	letter-spacing: 0.5px;
	text-decoration: none;
}


h1 a:hover,
h1:hover a {
	border-bottom-color: transparent;
	color: #1e6295;
}

h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
	border-bottom-color: transparent;
	color: #176a99;
}

p {
	margin-bottom: 0.75rem;
	color: #242424;
	font-size: 1rem;
	line-height: 1.4em;
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	h2 {
		font-size: 1.65rem;
		line-height: 1.9rem;
	}

	p {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		margin-top: 0.5rem;
		line-height: 2.25rem;
	}

	h2 {
		line-height: 1.8rem;
	}
}

/* buttons */
.inside-page-container .button,
.inside-page-container a.primary,
.homeboxes a.primary,
.primary,
.inside-page-container button[type="submit"],
.inside-page-container .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	padding: 0.85rem 50px 0.85rem 20px;
	border: 0px !important;
	border-radius: 3px;
	background-color: #163b68;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/white-arrow_1.svg');
	background-position: center right 20px;
	background-size: 15px;
	background-repeat: no-repeat;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff !important;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	text-decoration: none;
	margin-bottom: 5px;
	cursor: pointer;
}

.primary:hover,
.inside-page-container a.primary:hover .inside-page-container button[type="submit"]:hover,
.inside-page-container .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	border: 0px !important;
	background-color: #fcb31c;
	color: #fff !important;
	background-position: center right 10px;
}

.secondary,
.inside-page-container a.secondary,
.homeboxes a.secondary,
.inside-page-container button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-container .cms_form_button.secondary,
.inside-page-container .button.secondary {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 1rem;
	margin-left: 0rem;
	padding: 0.85rem 1.5rem;
	border: 0px !important;
	border-radius: 3px;
	background-color: #1e6295;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 350ms ease, border-color 350ms ease-out;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff !important;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	text-decoration: none;
	margin-bottom: 5px;
}

.secondary:hover,
.inside-page-container a.secondary:hover,
.inside-page-container button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-container .cms_form_button.secondary:hover,
.inside-page-container .button:hover,
.homeboxes a.secondary:hover {
	border: 0px !important;
	background-color: #0d4c7b;
	color: #fff !important;
	background-position: 85% 50%;
}

.tertiary,
.inside-page-container button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-container a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 0.85rem 1.5rem;
	border: 0px !important;
	border-radius: 3px;
	background-color: #5c5c5c;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 350ms ease, border-color 350ms ease-out;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff !important;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	text-decoration: none;
	margin-bottom: 5px;
}

.tertiary:hover,
.inside-page-container a.tertiary:hover,
.inside-page-container button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #fff;
	border: 0px !important;
	background-color: #474747;
	background-position: 85% 50%;
}

/* messages */
#message.success,
#polls .session_message.success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #569937;
	color: #fff;
	font-family: Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
}

#message.error,
#polls .session_message.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #dd4639;
	color: #fff;
	font-family: Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
}

#message.success p,
#message.error p {
	color: #fff;
	font-family: Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #163b68;
	color: white;
	font-weight: normal;
}

table.styled thead tr th p {
	color: inherit;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #1e6295;
	color: white;
	border-bottom: 1px solid #fff;
}

table.styled th p {
	color: inherit;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: rgba(0, 0, 0, 0.05);
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	margin-bottom: 5px;
	margin-top: 5px;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666666;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left: 5px solid #e2e2e2;
	margin: 0 0 10px 0;
	margin-bottom: 2rem;
	padding: 1rem 1.5rem;
	border-left-color: #f1ad02;
	background-color: transparent;
	color: #5c5c5c;
	font-size: 1.15rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}


/*********************** Footer **********************/
/*****************************************************/
.footer-nav-list li:last-child {
	padding-right: 0px;
}

#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

.footer-signoff-link-wrap {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.vertical-footer-divider {
	height: 17px;
}

.footer-notice p {
	font-size: 0.9rem;
	line-height: 1.15rem;
	font-weight: 400;
	text-align: left;
	font-style: italic;
}

.footer-notice a {
	border-bottom: 1px solid #828282;
	color: #1e6295;
}

.footer-notice a:hover {
	border-bottom: 1px solid #163b68;
}

@media (min-width: 991px) {
	.footer-signoff-link-wrap li {
		text-align: center;
	}

	.footer-nav-list {
		align-items: center;
	}

	.footer-nav-item,
	.footer-signoff-link {
		text-align: center;
	}
}

@media (max-width: 991px) {
	#GRIPFooterLogo {
		margin-left: auto;
		margin-right: auto;
	}

	.footer-notice p {
		margin-bottom: 0rem;
		font-size: 0.9rem;
		text-align: left;
	}
}

@media (max-width: 767px) {
	.vertical-footer-divider {
		display: none;
	}

	.footer-signoff-link-wrap li {
		margin-bottom: 0.25rem;
	}

	#GRIPFooterLogo {
		margin-top: -0.25rem;
	}
}

@media (max-width: 479px) {
	#GRIPFooterLogo {
		margin-top: 1.5rem;
	}

	.cb-wrapper.footer-notice {
		margin-top: 0;
	}

	.footer-notice p {
		font-size: 0.85rem;
		line-height: 20px;
		text-align: center;
	}
}


/*********************** Modules ********************/
/*****************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/* datepicker */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {

	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/*gdpr */
#cookie-consent-container {
	border-top: 5px solid #fcb31c;
	background-color: #092c56;
	padding: 1rem 3rem 2rem 4rem;
	display: flex;
	padding-right: 4rem;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 400;
}

#cookie-consent-container.minimized {
	border: 0;
	background-color: #fcb31c;
	display: block;
}

#cookie-consent-container .consent-actions a {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
}

#cookie-consent-container .consent-actions a:hover {
	border-bottom-color: #fff;
	color: #fff;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	padding: 0.85rem 1.5rem;
	border-width: 1px;
	border-color: #fff;
	border-radius: 3px;
	background-color: #1e6295;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 350ms ease, border-color 350ms ease-out;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	text-decoration: none;
}

#cookie-consent-container .consent-actions a.consent-all-trigger:hover,
#cookie-consent-container .consent-actions a.reject-all-trigger:hover {
	border-style: none;
	border-color: rgba(255, 255, 255, 0.3);
	background-color: #0d4c7b;
	background-position: 85% 50%;
}

#cookie-consent-container .consent-actions {
	text-align: center;
	order: 1;
}

.consent-dismiss-trigger-container {
	text-align: right;
	position: absolute;
	top: 15px;
	right: 25px;
}

#cookie-consent-form-container form .consent-header {
	text-align: center;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
}

#cookie-consent-form-container form .consent-footer a {
	border-bottom: 1px solid rgba(22, 59, 104, 0.15);
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	line-height: 1.15rem;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-decoration: none;
}

#cookie-consent-form-container form .consent-footer a:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.consent-form-visible #cookie-consent-form-container form {
	top: 8rem;
}

@media (max-width: 991px) {
	#cookie-consent-container {
		flex-wrap: wrap;
		padding-left: 2rem;
		padding-bottom: 2.5rem;
		font-size: 0.8rem;
		line-height: 1.25rem;
		padding-top: 0px;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 100%;
		padding-right: 40px;
		margin-bottom: 5px;
	}

	#cookie-consent-container .consent-actions {
		text-align: left;
		width: 100%;
	}

	#cookie-consent-container .consent-actions .consent-actions-container {
		align-items: center;
		flex-direction: row;
	}
}

@media (max-width: 479px) {
	#cookie-consent-container {
		padding-top: 50px;
		text-align: center;
		padding-bottom: 35px;
		padding-right: 1rem;
	}

	#cookie-consent-container .consent-actions .consent-actions-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
	}
}

/* alerts */
#alerts_module.list .item_divider,
#alerts_module .item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0px 1.3rem 0px;
}

#alerts_module.list .title h2 {
	margin-top: 0px;
}

#alerts_module_subscribe_form.form .item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15) !important;
	margin-bottom: 0 !important;
}

#alerts_module_subscribe_form .form_container h3 {
	padding-top: 0.75rem;
}

#alerts_module.form .field {
	padding-bottom: 15px;
}

#alerts_module.form .field small {
	display: block;
}

#alerts_module_subscribe_form .form_container h3 {
	margin-bottom: 0px;
}

@media (max-width: 991px) {
	#alerts_module_subscribe_form.form .form_container {
		width: 100%;
	}

	#alerts_module.form .form_container .form_item .field,
	#alerts_module.form .form_container .form_item .field input[type="text"] {
		width: 100% !important;
		display: block;
		text-align: left;
		background: none;
	}

	#alerts_module.form .form_container .form_item .field {
		padding-left: 0px;
	}

	#alerts_module.form .form_container .form_item .label,
	#alerts_module.form .form_container .form_item.reqiuired .label {
		display: inline-block;
		text-align: left;
		width: auto;
	}

	#alerts_module.form .form_container .form_item.required .label {
		background: url('/core/public/shared/assets/images/required.gif') no-repeat right 11px;
		padding-right: 15px;
	}
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}


#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

#events_module .fc-day-grid-event .fc-time {
	font-weight: normal;
}

@media (max-width: 991px) {

	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 0;
	padding: 0.75rem 0;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.collection.collection--files .field--file--title a,
.collection.collection--categories .field--category--title a {
	padding-left: 0px;
	margin-left: 0px;
}

.collection.collection--files .field--file--title,
.collection.collection--files .field--category--title {
	background-position: 0 3px;
}

.right-sidebar-col .box.filter .filter-row .select2-container,
.right-sidebar-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-sidebar-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-sidebar-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-sidebar-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-sidebar-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #666666;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-sidebar-col .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.right-sidebar-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

.right-sidebar-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input {
	border: 1px solid #fff;
	outline: 1px solid #fff !important;
}

.right-sidebar-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input:focus {
	border: 1px solid #666;
	outline: 1px solid #666 !important;
}


/* glossary */

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}


/* posts */
.posts-node .posts-comments .required_information {
	color: #ee0000;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #163b68;
	margin-top: 20px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	background-color: #fff;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.right-sidebar-col .box.posts-blocks.posts-blocks-rss-feeds a,
.right-sidebar-col .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
}


.right-sidebar-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-sidebar-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-sidebar-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-sidebar-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-sidebar-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-sidebar-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 39px;
	margin: 0;
}

.right-sidebar-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 8px 10px 7px 10px !important;
	background-color: #163b68 !important;
	background-image: none;
}

.right-sidebar-col .posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select {
	margin-bottom: 10px;
}


/* resources */
.right-sidebar-col .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}


/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #666;
	font-family: inherit;
	color: #ffffff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/******************* Content Boxes *******************/
/****************************************************/
/* news */
#news_module.homepage_contentbox .news-title h3,
#news_module.homepage_contentbox .news-title h3 a {
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	border-bottom: 0px;
	text-decoration: none;
	margin: 0;
	letter-spacing: inherit;
}

#news_module.homepage_contentbox .paragraph p,
#news_module.homepage_contentbox .paragraph li,
#news_module.homepage_contentbox .paragraph a,
#news_module.homepage_contentbox .paragraph td,
#news_module.homepage_contentbox .paragraph span,
#news_module.homepage_contentbox .paragraph a:hover {
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	border-bottom: 0px;
	text-decoration: none;
}

/* events */
#events_module.homepage_contentbox {
	width: 100%;
	position: relative;
}

#events_module.homepage_contentbox .footer {
	position: absolute;
	right: 0;
	top: -55px;
}

#events_module.homepage_contentbox .calendar-cb {
	border-bottom: 0px;
}

@media screen and (max-width: 991px) {
	#events_module.homepage_contentbox .footer {
		position: static;
		right: 0;
		top: 0;
		order: 0;
		display: block;
		margin: 0 auto;
		margin-bottom: 2rem;
		text-align: center;
	}
}

/* alerts */
#alerts_module.homepage_contentbox.list_alerts .alerts a:link,
#alerts_module.homepage_contentbox.list_alerts .alerts a:active,
#alerts_module.homepage_contentbox.list_alerts .alerts a:visited {
	color: #163b68;
	font-size: inherit;
}

#alerts_module.homepage_contentbox.list_alerts ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

#alerts_module.homepage_contentbox.list_alerts li {
	line-height: 18px;
}

.slider-section-wrapper #alerts_module.homepage_contentbox {
	margin-top: 200px;
}

/* files */
.cboxes .slider-section-wrapper .files-collection {
	margin-top: 230px;
}

.cboxes .files-collection p {
	margin-top: 0.7rem;
}


/* partners */
.cboxes .slider-section-wrapper #partners_module.homepage_contentbox {
	margin-top: 230px;
}

/* posts */
.cboxes .slider-section-wrapper .collection.posts-collection {
	margin-top: 250px;
}

.cboxes .posts-field {
	line-height: 1rem;
}

/* form */
.cboxes .slider-section-wrapper #submissionforms_module.homepage_contentbox {
	margin-top: 250px;
}

#submissionforms_module.homepage_contentbox,
#submissionforms_module.homepage_contentbox td {
	text-align: left;
	line-height: 1.3rem;
	font-size: 0.9rem;
	font-weight: normal;
	text-transform: none;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* Custom Landing Pages */
.grid-container {
	margin-top: 157px;
}

.inside-row .grid-container {
	margin-top: 0px;
}

.grid-container .contentbox-container {
	padding: 3rem 8%;
	grid-gap: 15px 30px;
}

.grid-container .contentbox-container.grey-background {
	background-color: #f8f8f8;
}

.grid-container .content-box .content-box-title h2,
.grid-container .content-box .content-box-content h2 {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	font-size: 2.25rem;
	line-height: 2rem;
	font-weight: 400;
	letter-spacing: 0.75px;
	text-decoration: none;
	margin-bottom: 1rem;
	text-transform: none;
	margin-top: 0px;
}

.grid-container .content-box .content-box-content {
	font-size: 1.15rem;
	line-height: 1.5rem;
	padding-bottom: 0rem;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #242424;
	margin-bottom: 1rem;
}

.grid-container .content-box .content-box-content p,
.grid-container .content-box .content-box-content li,
.grid-container .content-box .content-box-content td,
.grid-container .content-box .content-box-content span {
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	border: 0px;
	text-decoration: none;
}

.grid-container .content-box .content-box-content a {
	border-bottom: 1px solid rgba(22, 59, 104, 0.15);
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #163b68;
	line-height: 1.15rem;
	font-weight: 400;
	letter-spacing: 0.15px;
	text-decoration: none;
}

.grid-container .content-box .content-box-content a:hover {
	border-bottom: none;
}

.grid-container .content-box .content-box-content p:last-child {
	margin-bottom: 0px;
}

.grid-container .content-box .content-box-image-container {
	max-width: 350px;
	margin-bottom: 10px;
}

.grid-container .content-box .content-box-read-more a {
	display: inline-block;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 0.85rem 3.25rem 0.85rem 1.5rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none !important;
	border-width: 1px;
	border-color: #fff !important;
	border-radius: 3px;
	background-color: #163b68;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/white-arrow_1.svg');
	background-position: 85% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	-webkit-transition: all 350ms ease, border-color 350ms ease-out;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff !important;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	text-decoration: none !important;
}

.grid-container .content-box .content-box-read-more a:hover {
	border-style: none;
	border-color: rgba(255, 255, 255, 0.3);
	background-color: #fcb31c !important;
	background-position: 90% 50%;
}

.grid-container .content-box.notice p {
	font-size: 0.9rem;
	line-height: 1.15rem;
	font-weight: 400;
	text-align: left;
	font-style: italic;
}

.grid-container .content-box.center-content,
.grid-container .content-box.center-content-no-title {
	max-width: 800px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.grid-container .content-box.center-content .content-box-title h2,
.grid-container .content-box.center-content-no-title .content-box-title h2 {
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #1e6295;
	font-size: 0.825rem;
	line-height: 0.9rem;
	margin: 0px;
	padding: 0px;
	margin-bottom: 0.5rem;
}

.grid-container .content-box.default-no-title .content-box-title h2 {
	display: none;
}


.grid-container .alert-cb {
	height: 100%;
}

.grid-container #news_module.homepage_contentbox .view-all-link {
	top: -40px;
}

.grid-container .content-box.notice .content-box-content a {
	border-bottom: 1px solid rgba(22, 59, 104, 0.25);
	color: #1e6295;
}

.grid-container .content-box.notice .content-box-content a:hover {
	border-bottom: 1px solid #163b68;
}

.grid-container .alert-cb._2 {
	margin-left: 0px;
}

.grid-container .news-cb-inner-wrapper.first {
	border-bottom: 0px !important;
}

.grid-container #news_module.homepage_contentbox .news-cb-inner-wrapper.first {
	min-height: 275px;
}

.grid-container .slider-section-wrapper {
	margin-top: 0px;
}

.grid-container .alert-cb.xs .alert-img {
	width: 35px;
}

.grid-container .alert-cb.xs .heading-4 {
	font-size: 1.3rem;
	line-height: 1.5rem;
	margin-bottom: 0px;
}

@media (max-width: 991px) {
	.grid-container .content-box .content-box-title h2 {
		margin-bottom: 0rem;
	}

	.grid-container .contentbox-container {
		padding: 4rem 6%;
	}

	.grid-container .content-box.notice p {
		margin-bottom: 0rem;
		font-size: 0.9rem;
		text-align: left;
	}

	.grid-container #news_module.homepage_contentbox .view-all-link {
		margin-top: 1rem;
	}

	.grid-container .alert-cb._2 {
		margin-top: 0;
	}

	.grid-container .alert-cb.xs .heading-4 {
		font-size: 1.15rem;
		line-height: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.grid-container .contentbox-container {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.grid-container .content-box .content-box-content {
		font-size: 1.05rem;
		line-height: 1.4rem;
	}

	.grid-container .alert-cb.xs .alert-img {
		display: none;
	}
}

@media screen and (max-width: 479px) {
	.grid-container {
		margin-top: 180px;
	}

	.inside-row .grid-container {
		margin-top: 0px;
	}

	.grid-container .contentbox-container {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.grid-container .content-box .content-box-title h2,
	.grid-container .content-box .content-box-content h2 {
		font-size: 1.75rem;
		line-height: 1.9rem;
		text-align: center;
		margin-bottom: 0;
	}

	.grid-container .content-box .content-box-content {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	.grid-container .content-box.notice p {
		font-size: 0.85rem;
		line-height: 20px;
		text-align: center;
	}

	.grid-container .alert-cb.xs .heading-4 {
		font-size: 1.15rem;
		line-height: 1.25rem;
	}
}


/* grid styles */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	box-sizing: border-box;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

.grid-box img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}


/******** Custom styles ********/
/******************************/
/* Buttons */
.black-btn {
	border: 1px solid #242424 !important;
	color: #242424 !important;
	padding: 0.85rem 1.5rem 0.85rem 1.5rem !important;
	font-size: 0.9rem !important;
	letter-spacing: 0.5px !important;
	text-decoration: none;
	transition: all 350ms ease, border-color 350ms ease-out;
	border-radius: 3px;
	display: inline-block;
	background-color: transparent;
	text-align: center;
}

.black-btn:hover {
	background-color: #242424 !important;
	color: #fff !important;
}

.dark-blue-btn {
	border: 1px solid #001b4d !important;
	color: #242424 !important;
	padding: 0.85rem 1.5rem 0.85rem 1.5rem !important;
	font-size: 0.9rem !important;
	letter-spacing: 0.5px !important;
	text-decoration: none;
	transition: all 350ms ease, border-color 350ms ease-out;
	border-radius: 3px;
	display: inline-block;
	background-color: transparent;
	text-align: center;
}

.dark-blue-btn:hover {
	color: #fff !important;
	background-color: #001b4d !important;
}

.medium-blue-btn {
	border: 1px solid #001b4d !important;
	color: #242424 !important;
	padding: 0.85rem 1.5rem 0.85rem 1.5rem !important;
	font-size: 0.9rem !important;
	letter-spacing: 0.5px !important;
	text-decoration: none;
	transition: all 350ms ease, border-color 350ms ease-out;
	border-radius: 3px;
	display: inline-block;
	background-color: transparent;
	text-align: center;
}

.medium-blue-btn:hover {
	color: #fff !important;
	background-color: #1e6295 !important;
}

.light-blue-btn {
	border: 1px solid #1e6295 !important;
	color: #242424 !important;
	padding: 0.85rem 1.5rem 0.85rem 1.5rem !important;
	font-size: 0.9rem !important;
	letter-spacing: 0.5px !important;
	text-decoration: none;
	transition: all 350ms ease, border-color 350ms ease-out;
	border-radius: 3px;
	display: inline-block;
	background-color: transparent;
	text-align: center;
}

.light-blue-btn:hover {
	color: #fff !important;
	background-color: #569acc !important;
}

.gold-btn {
	border: 1px solid #f1ad02 !important;
	color: #242424 !important;
	padding: 0.85rem 1.5rem 0.85rem 1.5rem !important;
	font-size: 0.9rem !important;
	letter-spacing: 0.5px !important;
	text-decoration: none;
	transition: all 350ms ease, border-color 350ms ease-out;
	border-radius: 3px;
	display: inline-block;
	background-color: transparent;
	text-align: center;
}

.gold-btn:hover {
	color: #fff !important;
	background-color: #f1ad02 !important;
}

/* Dropdowns */
.hover-dropdown-container {
	position: relative;
	display: inline-block;
}

.hover-dropdown-title {
	background-color: #1e6295;
	color: #fff;
	padding: 20px;
	display: inline-flex;
	cursor: pointer;
}

.hover-dropdown-title:after {
	font-family: 'webflow-icons';
	content: '\e603';
	display: block;
	width: 10px;
	height: 10px;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	margin-right: 5px;
	margin-left: 15px;
}

.hover-dropdown-items {
	display: none;
	position: absolute;
	background: #dddddd;
	width: 100%;
	z-index: 2000;
}

.hover-dropdown-container:hover .hover-dropdown-items {
	display: block;
}

.hover-dropdown-items a {
	padding: 10px 20px;
	display: block;
	color: #222222;
	border-bottom: 0px !important;
}

.hover-dropdown-items a:hover {
	background-color: #f1ad02;
}

.hover-dropdown-bar {
	background-color: #1e6295;
	margin-top: 10px;
	margin-bottom: 10px;
}

/* Round Button */
.round-btn {
	display: inline-block;
	padding: 0.85rem 3rem 0.85rem 1.5rem;
	border: 0px !important;
	background-color: #163b68 !important;
	background-image: url('/local/public/shared/assets/images/websites/white-arrow_1.svg');
	background-position: center right 23px;
	background-size: 15px;
	background-repeat: no-repeat;
	transition: all 350ms ease, border-color 350ms ease-out;
	color: #fff !important;
	font-size: 0.9rem !important;
	letter-spacing: 0.5px !important;
	text-decoration: none;
	border-radius: 50%;
}

.round-btn:hover {
	border: 0px !important;
	background-color: #fcb31c !important;
	color: #fff !important;
	background-position: center right 15px;
}

/* Button Group */
.btn-group-container {
	display: flex;
	flex-wrap: wrap;
	margin: 10px 0px;
}

.group-btn {
	border: 0px !important;
	padding: 0.85rem 1.5rem 0.85rem 1.5rem !important;
	background-color: #163b68 !important;
	color: #fff !important;
	font-size: 0.9rem !important;
	letter-spacing: 0.5px !important;
	text-decoration: none;
	display: inline-block;
	transition: all 350ms ease, border-color 350ms ease-out;
	text-align: center;
}

.group-btn:hover {
	color: #fff !important;
	background-color: #fcb31c !important;
}

.btn-group-container.vert {
	display: inline-flex;
	flex-direction: column;
}

.btn-group-container.vert .group-btn {
	min-width: 150px;
}

/* Alert */
.alert-container {
	position: relative;
	padding: 1rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25);
	background-color: #ffb6a4;
	font-size: 1rem;
}

.alert-container .alert-text h1,
.alert-container .alert-text h2,
.alert-container .alert-text h3,
.alert-container .alert-text h4,
.alert-container .alert-text h4,
.alert-container .alert-text h6 {
	color: #242424;
	margin-top: 0rem;
}

.alert-container .alert-close {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 50px;
	height: 50px;
	font-size: 2rem;
	line-height: 1;
	cursor: pointer;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #333;
	display: flex;
	align-items: center;
	justify-content: center;
}

.alert-container .alert-close:hover {
	background-color: #f3f5f9;
}

/* Tool Tip */
.tooltip-container {
	display: inline-block;
	position: relative;
}

.tooltip-container p {
	border-bottom: 1px dotted #000;
	font-size: 14px;
	line-height: 20px;
	display: inline-block;
}

.tooltip-container .tooltip-text {
	position: absolute;
	left: 50%;
	bottom: 100%;
	z-index: 1;
	display: none;
	min-width: 120px;
	margin-left: -60px;
	padding: 5px;
	border-radius: 6px;
	background-color: #242424;
	color: #fff;
	line-height: 1.2;
	text-align: center;
}

.tooltip-container:hover .tooltip-text {
	display: inline-block;
}

/* Card */
.card-container {
	display: inline-block;
	overflow: hidden;
	width: 350px;
	height: 500px;
	margin-right: 1rem;
	border: 1px solid rgba(0, 0, 0, 0.5) !important;
	border-radius: 10px;
	box-shadow: 0 0 3px 2px rgb(36 36 36 / 25%);
	transition: all 200ms ease;
	text-decoration: none;
}

.card-container:hover {
	-webkit-transform: scale(1.025);
	-ms-transform: scale(1.025);
	transform: scale(1.025);
}

.card-container .card-image {
	height: auto;
}

.card-container .card-content {
	margin: 1rem;
	display: inline-block;
}

.card-container .card-content .card-heading {
	margin-bottom: 0.5rem;
	color: #475872;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

.card-container .card-content .card-text {
	margin-bottom: 1rem;
	color: #474747;
	font-size: 14px;
	line-height: 20px;
	display: block;
}

/* Accordion */
.accordion-container .accordion-box {
	display: block;
	width: 90%;
	color: #fff;
	margin-left: auto;
	margin-right: auto;
}

.accordion-container .accordion-box .accordion-title {
	display: block;
	width: 100%;
	background-color: #163b68;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	line-height: 20px;
	padding: 20px;
}

.accordion-container .accordion-box .accordion-title:after {
	font-family: 'webflow-icons';
	content: '\e603';
	display: block;
	width: 10px;
	height: 10px;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	margin-right: 5px;
	margin-left: 15px;
	float: right;
}

.accordion-container .accordion-box .accordion-content {
	padding: 1rem;
	color: #242424;
	background: #ddd;
	font-size: 14px;
	line-height: 20px;
	display: none;
}

.accordion-container .accordion-box .accordion-content p {
	font-size: 14px;
	line-height: 20px;
}

/* Expanding Card */
.expanding-card {
	display: inline-flex;
	width: 300px;
	padding: 1rem 1rem 0.5rem;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	grid-column-gap: 1rem;
	border-style: solid;
	border-width: 2px;
	border-color: #1e6295;
	border-radius: 5px;
	background-color: rgba(30, 98, 149, 0.08);
	font-size: 1rem;
}

.expanding-title {
	display: flex;
	width: 100%;
	align-items: center;
	color: #333 !important;
	font-weight: 700 !important;
	text-decoration: none;
	border-bottom: 0px !important;
	display: flex;
	justify-content: space-between;
}

.expanding-more-info {
	font-size: 0.9rem;
	margin-top: 1rem;
	display: inline-block;
	position: relative;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	z-index: 900;
	width: 100%;
}

.expanding-more-info-label {
	display: flex;
	width: 100%;
	padding: 0.5rem 0.5rem 0px;
	justify-content: flex-end;
	align-items: center;
	grid-column-gap: 0.25rem;
	border-top: 1px solid rgba(30, 98, 149, 0.25);
	font-size: 0.8rem;
	font-weight: 400;
	text-align: right;
}

.more-info-label-text {
	color: #163b68;
	text-decoration: underline;
	cursor: pointer;
}

.expanding-more-info-content {
	position: static;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	font-weight: 400;
	border-top: 1px solid rgba(30, 98, 149, 0.25);
	background-color: transparent;
	display: none;
}

.expanding-more-info-content p {
	font-size: 0.9rem;
}

.expanding-more-info-content p strong a {
	font-weight: 700 !important;
	text-decoration: underline;
	border-bottom: 0px !important;
}

.expanding-more-info-content p a {
	color: #1e6295;
}

/* no bullet */
.no-bullet {
	padding-left: 20px;
	list-style-type: none;
}

/* pagination */
.pagination {
	display: flex;
	margin-bottom: 0px;
	padding-left: 0px;
	line-height: 25px;
	text-align: center;
	list-style-type: none;
}

.pagination a {
	text-decoration: none;
	width: 50px;
	height: 50px;
	display: inline-block;
	line-height: 50px;
	border-bottom: 0px !important;
	color: #1e6295;
}

.pagination a.active {
	background-color: #1e6295;
	color: #fff;
}

.pagination a:hover:not(.active) {
	background-color: #ddd;
}

/* grid styles */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}
