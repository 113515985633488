.cb-feature.news {
	width: 100%;
}

#news_module.homepage_contentbox > div.news-cb:last-of-type {
	margin-left: 3rem;
}

@media screen and (max-width: 991px) {
	#news_module.homepage_contentbox > div.news-cb:last-of-type {
		margin-left: 0;
	}
}
